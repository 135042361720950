import { Tab, Tabs } from "@material-ui/core";
import { ReactElement, useEffect, useState } from "react";
import { ProjectInfo } from "../types";
import ProjectList from "./ProjectList";

interface ProjectSelectProps {
  projects: ProjectInfo[];
  projectCode: string;
  onChange: (projectCode: string) => void;
}

export default function ProjectSelect(props: ProjectSelectProps): ReactElement {
  const [currentTab, setCurrentTab] = useState(0);

  const [activeProjects, setActiveProjects] = useState<ProjectInfo[]>([]);
  const [historicalProjects, setHistoricalProjects] = useState<ProjectInfo[]>(
    []
  );

  useEffect(() => {
    setActiveProjects(props.projects.filter((p) => !p.historical));
    setHistoricalProjects(props.projects.filter((p) => p.historical));
  }, [props.projects]);

  useEffect(() => {
    if (props.projectCode) {
      const project = props.projects.find((p) => p.id === props.projectCode);
      if (project) {
        setCurrentTab(project.historical ? 1 : 0);
      }
    }
  }, [props.projects, props.projectCode]);

  return (
    <>
      <Tabs
        variant="fullWidth"
        value={currentTab}
        onChange={(_, v) => setCurrentTab(v)}
      >
        <Tab label="Active" />
        <Tab label="Historical" />
      </Tabs>

      <ProjectList
        hidden={currentTab !== 0}
        projects={activeProjects}
        projectCode={props.projectCode}
        onChange={props.onChange}
      />

      <ProjectList
        hidden={currentTab !== 1}
        projects={historicalProjects}
        projectCode={props.projectCode}
        onChange={props.onChange}
      />
    </>
  );
}
