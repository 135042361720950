import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import { ReactElement, useState } from "react";
import { UploadPropertyResult } from "../api";
import TablePaginationActions from "./TablePaginationActions";

interface ProjectPropertiesImportResultsDialogProps {
  importType: number;
  importResults: UploadPropertyResult[];
  resultsUrl: string;
  open: boolean;
  onClose: () => void;
}

export default function ProjectPropertiesImportResultsDialog(
  props: ProjectPropertiesImportResultsDialogProps
): ReactElement {
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage] = useState(16);

  return (
    <Dialog fullWidth open={props.open} maxWidth="md">
      <DialogTitle>Properties Import Results</DialogTitle>
      <DialogContent>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {props.importType === 1 ? "Field Code" : "Reservoir Code"}
              </TableCell>
              <TableCell>
                {props.importType === 1 ? "Field Name" : "Reservoir Name"}
              </TableCell>
              <TableCell>Import Success</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.importResults
              .slice(
                tablePage * rowsPerPage,
                tablePage * rowsPerPage + rowsPerPage
              )
              .map((item) => {
                return (
                  <TableRow key={item.fieldName}>
                    <TableCell>
                      <Typography variant="caption" component="span">
                        {props.importType === 1
                          ? item.fieldCode
                          : item.reservoirCode}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption" component="span">
                        {props.importType === 1
                          ? item.fieldName
                          : item.reservoirName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        size="small"
                        label={item.importSuccess ? "True" : "False"}
                        icon={item.importSuccess ? <DoneIcon /> : <ErrorIcon />}
                        color={item.importSuccess ? "primary" : "secondary"}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption" component="span">
                        {item.importFailureMessage}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={4}
                count={props.importResults.length}
                rowsPerPage={rowsPerPage}
                page={tablePage}
                onPageChange={(_, newPage) => setTablePage(newPage)}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </DialogContent>
      <DialogActions>
        <a
          href={
            "data:application/octet-stream," +
            encodeURIComponent(props.resultsUrl)
          }
          target="_blank"
          rel="noopener noreferrer"
          download="results.csv"
        >
          <Button color="primary" variant="contained">
            <span
              style={{
                textDecoration: "none",
                textDecorationStyle: "unset",
              }}
            >
              Download Report
            </span>
          </Button>
        </a>

        <Button color="primary" autoFocus onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
