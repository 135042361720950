import { Button, IconButton, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { ReactElement, useEffect, useState } from "react";

interface FilterPropertiesProps {
  filterText: string;
  filterPropertiesHandler: (filterText: string) => void;
}

export default function FilterProperties(
  props: FilterPropertiesProps
): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [filterText, setFilterText] = useState(props.filterText);

  useEffect(() => {
    setFilterText(props.filterText);
  }, [props.filterText]);

  const search = () => {
    props.filterPropertiesHandler(filterText);
  };

  const clear = () => {
    props.filterPropertiesHandler("");

    setIsOpen(false);
  };

  if (isOpen) {
    return (
      <TextField
        size="small"
        margin="none"
        color="secondary"
        variant="outlined"
        placeholder="Search"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        onKeyUp={(e) => e.key === "Enter" && search()}
        InputProps={{
          endAdornment: (
            <>
              <IconButton size="small" onClick={search} autoFocus>
                <SearchIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={clear}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </>
          ),
        }}
      />
    );
  } else {
    return (
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        startIcon={<SearchIcon />}
        onClick={() => setIsOpen(true)}
      >
        Search...
      </Button>
    );
  }
}
