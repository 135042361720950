import { Box, Button, Chip, Divider, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { apiGetProjectDocuments, ProjectDocument } from "../api";
import ProjectReportAddDialog from "./ProjectReportAddDialog";
import ProjectReportRemoveDialog from "./ProjectReportRemoveDialog";

interface ProjectReportsProps {
  projectCode: string;
  projectName: string;
  editingEnabled: boolean;
}

export default function ProjectReports(
  props: ProjectReportsProps
): ReactElement {
  const [documents, setDocuments] = useState<ProjectDocument[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<ProjectDocument>();

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  useEffect(() => {
    if (!props.projectCode || isAddDialogOpen || isRemoveDialogOpen) return;

    apiGetProjectDocuments(props.projectCode)
      .then((data) => setDocuments(data))
      .catch((e) => console.log("Error loading project reports: " + e.message));
  }, [props.projectCode, isAddDialogOpen, isRemoveDialogOpen]);

  return (
    <>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ flexGrow: 1 }}>
          Selected Project Reports
        </Typography>

        {props.editingEnabled && (
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setIsAddDialogOpen(true)}
          >
            Add...
          </Button>
        )}
      </Box>

      <Box marginTop={1} marginBottom={2}>
        <Divider />
      </Box>

      {!props.projectCode && (
        <Typography variant="caption" color="textSecondary">
          No project selected.
        </Typography>
      )}

      {props.projectCode && !props.projectName && (
        <Typography variant="caption" color="textSecondary">
          Project {props.projectCode} does not exist.
        </Typography>
      )}

      {props.projectCode && props.projectName && documents.length === 0 && (
        <Typography variant="caption" color="textSecondary">
          No reports attached to project {props.projectCode}.
        </Typography>
      )}

      {documents.map((doc) => (
        <Fragment key={"fr_" + doc.id}>
          <Chip
            clickable
            color="primary"
            component="a"
            size="small"
            key={doc.id}
            label={doc.filename}
            style={{ marginRight: "5px", marginBottom: "5px" }}
            onClick={() => window.open(doc.href, "_blank")}
            onDelete={() => {
              setSelectedDocument(doc);
              setIsRemoveDialogOpen(true);
            }}
          />
        </Fragment>
      ))}

      <ProjectReportAddDialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        projectCode={props.projectCode}
        projectName={props.projectName}
      />

      <ProjectReportRemoveDialog
        open={isRemoveDialogOpen}
        onClose={() => setIsRemoveDialogOpen(false)}
        documentId={selectedDocument?.id || 0}
        documentName={selectedDocument?.filename || ""}
      />
    </>
  );
}
