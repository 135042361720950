import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CSSProperties, ReactElement, useEffect, useState } from "react";
import { apiGetReservoirs } from "../api";
import { Reservoir, ReservoirOrder } from "../types";

interface SelectReservoirProps {
  style?: CSSProperties;
  reservoirId: number;
  onChange: (reservoirId: number) => void;
  hasProjects?: boolean;
  showCount?: "project";
}

export default function SelectReservoir(
  props: SelectReservoirProps
): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [reservoirs, setReservoirs] = useState<Reservoir[]>([]);
  const [selected, setSelected] = useState<Reservoir | null>(null);

  useEffect(() => {
    if (!props.reservoirId && !isOpen) return;

    // We already have this reservoir, no need to (re)load
    if (reservoirs.some((x) => x.id === props.reservoirId)) return;

    const timeout = setTimeout(() => {
      setIsLoading(true);
      apiGetReservoirs({
        ids: !isOpen && !userInput ? [props.reservoirId] : undefined,
        codeOrNameStartsWith: userInput,
        hasProjects: props.hasProjects,
        order: ReservoirOrder.NAME_ASC,
        take: 100,
      })
        .then((data) => {
          setIsLoading(false);
          setReservoirs(data);
        })
        .catch(() => {
          setIsLoading(false);
          setReservoirs([]);
        });
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reservoirId, props.hasProjects, isOpen, userInput]);

  useEffect(() => {
    setSelected(reservoirs.find((x) => x.id === props.reservoirId) || null);
  }, [props.reservoirId, reservoirs]);

  const formatLabel = (reservoir: Reservoir) => {
    const label = reservoir.name + ", " + reservoir.code;
    switch (props.showCount) {
      case "project":
        return label + ` (${reservoir.projectCount})`;
      default:
        return label;
    }
  };

  return (
    <Autocomplete
      size="small"
      loading={isLoading}
      options={reservoirs}
      value={selected}
      getOptionLabel={formatLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Reservoir"
          placeholder="Filter by reservoir"
          InputLabelProps={{ shrink: true }}
        />
      )}
      style={props.style}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onChange={(_, value) => {
        setSelected(value);
        props.onChange(value?.id || 0);
      }}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setUserInput(value);
        }
      }}
    />
  );
}
