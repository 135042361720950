import { Box, Button, ButtonGroup, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { ReactElement, useState } from "react";
import { UploadPropertyResult } from "../api";
import FilterProperties from "./FilterProperties";
import ProjectPropertiesExportDialog from "./ProjectPropertiesExportDialog";
import ProjectPropertiesImportDialog from "./ProjectPropertiesImportDialog";
import ProjectPropertiesImportResultsDialog from "./ProjectPropertiesImportResultsDialog";

export interface ProjectEditToolbarProps {
  projectCode: string;
  projectName: string;
  addingMode: boolean;
  editingMode: boolean;
  editingEnabled: boolean;
  filterPropertiesText: string;
  saveChangesHandler: () => void;
  updateFormHandler: (projectCode: unknown) => void;
  editProjectHandler: () => void;
  addProjectPropertiesHandler: () => void;
  cancelEditProjectHandler: () => void;
  filterPropertiesHandler: (filterText: string) => void;
}

export default function ProjectEditToolbar(
  props: ProjectEditToolbarProps
): ReactElement {
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [isResultsDialogOpen, setIsResultsDialogOpen] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

  const [importResultsData, setImportResultsData] = useState<
    UploadPropertyResult[]
  >([]);
  const [importResultsType, setImportResultsType] = useState(1);
  const [importResultsCsv, setImportResultsCsv] = useState("");

  return (
    <Box style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
      <Typography component="span" variant="subtitle2" style={{ flexGrow: 1 }}>
        Selected Project Properties
      </Typography>

      {props.editingEnabled && props.projectCode !== "" && (
        <>
          <FilterProperties
            filterText={props.filterPropertiesText}
            filterPropertiesHandler={props.filterPropertiesHandler}
          />

          {props.editingMode || props.addingMode ? (
            <>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                style={{ marginLeft: "10px", minWidth: "80px" }}
                onClick={props.saveChangesHandler}
              >
                Save
              </Button>

              <Button
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                style={{ marginLeft: "10px", minWidth: "80px" }}
                onClick={props.cancelEditProjectHandler}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <ButtonGroup style={{ marginLeft: "10px" }}>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  style={{ minWidth: "70px" }}
                  onClick={props.addProjectPropertiesHandler}
                >
                  Add
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  startIcon={<EditIcon />}
                  style={{ minWidth: "70px" }}
                  onClick={props.editProjectHandler}
                >
                  Edit
                </Button>
              </ButtonGroup>

              <ButtonGroup style={{ marginLeft: "10px" }}>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  onClick={() => setIsImportDialogOpen(true)}
                >
                  Import...
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => setIsExportDialogOpen(true)}
                >
                  Export...
                </Button>
              </ButtonGroup>
            </>
          )}
        </>
      )}

      <ProjectPropertiesImportDialog
        projectCode={props.projectCode}
        projectName={props.projectName}
        open={isImportDialogOpen}
        onClose={(uploadType, result) => {
          setIsImportDialogOpen(false);

          if (uploadType > 0 && result) {
            setImportResultsData(result.uploadResults);
            setImportResultsType(uploadType);
            setImportResultsCsv(result.csvResults);
            setIsResultsDialogOpen(true);

            props.updateFormHandler(props.projectCode);
          }
        }}
      />

      <ProjectPropertiesImportResultsDialog
        importResults={importResultsData}
        importType={importResultsType}
        resultsUrl={importResultsCsv}
        open={isResultsDialogOpen}
        onClose={() => setIsResultsDialogOpen(false)}
      />

      <ProjectPropertiesExportDialog
        projectCode={props.projectCode}
        projectName={props.projectName}
        open={isExportDialogOpen}
        onClose={() => setIsExportDialogOpen(false)}
      />
    </Box>
  );
}
