import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ListIcon from "@material-ui/icons/List";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Alert } from "@material-ui/lab";
import { ReactElement, useEffect, useState } from "react";
import {
  apiExportProjectsAsCsv,
  apiExportProjectsAsExcel,
  apiGetEvalTypes,
  apiGetGuidelines,
  apiGetProjectCount,
  apiGetProjects,
  apiGetWorkTypes,
} from "../api";
import {
  useSearchParam,
  useSearchParamArray,
  useSearchParamBoolean,
  useSearchParamNumber,
} from "../hooks";
import {
  EmptyProject,
  EvalType,
  Guideline,
  Project,
  ProjectOrder,
  WorkType,
} from "../types";
import {
  formatDateOnly,
  nullableBooleanFromString,
  nullableBooleanToString,
  saveBlob,
} from "../utils";
import Crosslink from "./Crosslink";
import ProjectInfoDialog from "./ProjectInfoDialog";
import ProjectReportsDialog from "./ProjectReportsDialog";
import SelectCountry from "./SelectCountry";
import SelectField from "./SelectField";
import SelectReservoir from "./SelectReservoir";
import TableDateCell from "./TableDateCell";
import TableFilterCell from "./TableFilterCell";
import TableHeaderCell from "./TableHeaderCell";
import TablePaginationActions from "./TablePaginationActions";
import TableSelectCell from "./TableSelectCell";

export default function Projects(): ReactElement {
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [workTypes, setWorkTypes] = useState<WorkType[]>([]);
  const [evalTypes, setEvalTypes] = useState<EvalType[]>([]);
  const [guidelines, setGuidelines] = useState<Guideline[]>([]);

  const [codeFilter, setCodeFilter] = useSearchParam("code", "");
  const [nameFilter, setNameFilter] = useSearchParam("name", "");
  const [asOfDateFrom, setAsOfDateFrom] = useSearchParam("asOfDateFrom", "");
  const [asOfDateTo, setAsOfDateTo] = useSearchParam("asOfDateTo", "");
  const [divisionFilter, setDivisionFilter] = useSearchParam("division", "");
  const [projLeaderFilter, setProjLeaderFilter] = useSearchParam(
    "projLeader",
    ""
  );
  const [projCoordinatorFilter, setProjCoordinatorFilter] = useSearchParam(
    "projCoordinator",
    ""
  );
  const [techCoordinatorFilter, setTechCoordinatorFilter] = useSearchParam(
    "techCoordinator",
    ""
  );
  const [clientNameFilter, setClientNameFilter] = useSearchParam(
    "clientName",
    ""
  );
  const [workTypeFilter, setWorkTypeFilter] =
    useSearchParamArray("workTypes[]");
  const [evalTypeFilter, setEvalTypeFilter] =
    useSearchParamArray("evalTypes[]");
  const [guidelineFilter, setGuidelineFilter] =
    useSearchParamArray("guidelines[]");
  const [countryFilter, setCountryFilter] = useSearchParam("countryCode", "");
  const [fieldFilter, setFieldFilter] = useSearchParamNumber("fieldId", 0);
  const [reservoirFilter, setReservoirFilter] = useSearchParamNumber(
    "reservoirId",
    0
  );
  const [hasFields, setHasFields] = useSearchParamBoolean("hasFields");
  const [hasReservoirs, setHasReservoirs] =
    useSearchParamBoolean("hasReservoirs");

  const [order, setOrder] = useSearchParam<ProjectOrder>(
    "order",
    ProjectOrder.ID_ASC
  );

  const [count, setCount] = useState(0);
  const [page, setPage] = useSearchParamNumber("page", 0);
  const [rowsPerPage, setRowsPerPage] = useSearchParamNumber("rowsPerPage", 10);
  const [projects, setProjects] = useState<Project[]>([]);

  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const [isReportsDialogOpen, setIsReportsDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project>(EmptyProject);

  useEffect(() => {
    apiGetWorkTypes().then((data) => {
      setWorkTypes(data);
    });

    apiGetEvalTypes().then((data) => {
      setEvalTypes(data);
    });

    apiGetGuidelines().then((data) => {
      setGuidelines(data);
    });
  }, []);

  useEffect(() => {
    setIsLoadingCount(true);
    apiGetProjectCount({
      codeContains: codeFilter,
      nameContains: nameFilter,
      asOfDateFrom: asOfDateFrom,
      asOfDateTo: asOfDateTo,
      divisionContains: divisionFilter,
      projLeaderContains: projLeaderFilter,
      projCoordinatorContains: projCoordinatorFilter,
      techCoordinatorContains: techCoordinatorFilter,
      clientNameContains: clientNameFilter,
      workTypeCodes: workTypeFilter,
      evalTypeCodes: evalTypeFilter,
      guidelineCodes: guidelineFilter,
      countryCode: countryFilter,
      fieldId: fieldFilter,
      reservoirId: reservoirFilter,
      hasFields: hasFields,
      hasReservoirs: hasReservoirs,
    })
      .then((data) => {
        setIsLoadingCount(false);
        setCount(data);
      })
      .catch((e) => {
        setIsLoadingCount(false);
        setErrorMessage(e.message);
      });
  }, [
    codeFilter,
    nameFilter,
    asOfDateFrom,
    asOfDateTo,
    divisionFilter,
    projLeaderFilter,
    projCoordinatorFilter,
    techCoordinatorFilter,
    clientNameFilter,
    workTypeFilter,
    evalTypeFilter,
    guidelineFilter,
    countryFilter,
    fieldFilter,
    reservoirFilter,
    hasFields,
    hasReservoirs,
  ]);

  useEffect(() => {
    setIsLoadingProjects(true);
    apiGetProjects({
      codeContains: codeFilter,
      nameContains: nameFilter,
      asOfDateFrom: asOfDateFrom,
      asOfDateTo: asOfDateTo,
      divisionContains: divisionFilter,
      projLeaderContains: projLeaderFilter,
      projCoordinatorContains: projCoordinatorFilter,
      techCoordinatorContains: techCoordinatorFilter,
      clientNameContains: clientNameFilter,
      workTypeCodes: workTypeFilter,
      evalTypeCodes: evalTypeFilter,
      guidelineCodes: guidelineFilter,
      countryCode: countryFilter,
      fieldId: fieldFilter,
      reservoirId: reservoirFilter,
      hasFields: hasFields,
      hasReservoirs: hasReservoirs,
      order: order,
      skip: page * rowsPerPage,
      take: rowsPerPage,
    })
      .then((data) => {
        setIsLoadingProjects(false);
        setProjects(data);
      })
      .catch((e) => {
        setIsLoadingProjects(false);
        setErrorMessage(e.message);
      });
  }, [
    codeFilter,
    nameFilter,
    asOfDateFrom,
    asOfDateTo,
    divisionFilter,
    projLeaderFilter,
    projCoordinatorFilter,
    techCoordinatorFilter,
    clientNameFilter,
    workTypeFilter,
    evalTypeFilter,
    guidelineFilter,
    countryFilter,
    fieldFilter,
    reservoirFilter,
    hasFields,
    hasReservoirs,
    order,
    page,
    rowsPerPage,
  ]);

  const handleExportCsv = () => {
    apiExportProjectsAsCsv({
      codeContains: codeFilter,
      nameContains: nameFilter,
      asOfDateFrom: asOfDateFrom,
      asOfDateTo: asOfDateTo,
      divisionContains: divisionFilter,
      projLeaderContains: projLeaderFilter,
      projCoordinatorContains: projCoordinatorFilter,
      techCoordinatorContains: techCoordinatorFilter,
      clientNameContains: clientNameFilter,
      workTypeCodes: workTypeFilter,
      evalTypeCodes: evalTypeFilter,
      guidelineCodes: guidelineFilter,
      countryCode: countryFilter,
      fieldId: fieldFilter,
      reservoirId: reservoirFilter,
      hasFields: hasFields,
      hasReservoirs: hasReservoirs,
      order: order,
    })
      .then((blob) => saveBlob(blob, "Projects.csv"))
      .catch((e) => setErrorMessage(e.message));
  };

  const handleExportExcel = () => {
    apiExportProjectsAsExcel({
      codeContains: codeFilter,
      nameContains: nameFilter,
      asOfDateFrom: asOfDateFrom,
      asOfDateTo: asOfDateTo,
      divisionContains: divisionFilter,
      projLeaderContains: projLeaderFilter,
      projCoordinatorContains: projCoordinatorFilter,
      techCoordinatorContains: techCoordinatorFilter,
      clientNameContains: clientNameFilter,
      workTypeCodes: workTypeFilter,
      evalTypeCodes: evalTypeFilter,
      guidelineCodes: guidelineFilter,
      countryCode: countryFilter,
      fieldId: fieldFilter,
      reservoirId: reservoirFilter,
      hasFields: hasFields,
      hasReservoirs: hasReservoirs,
      order: order,
    })
      .then((blob) => saveBlob(blob, "Projects.xlsx"))
      .catch((e) => setErrorMessage(e.message));
  };

  return (
    <Container
      component="main"
      maxWidth="xl"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Backdrop
        open={isLoadingCount || isLoadingProjects}
        style={{ zIndex: 100 }}
      >
        <CircularProgress />
      </Backdrop>
      <Box mb={2} display="flex" alignItems="center">
        <Button
          color="primary"
          variant="contained"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={handleExportCsv}
        >
          Export to CSV
        </Button>
        &nbsp;&nbsp;
        <Button
          color="primary"
          variant="contained"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={handleExportExcel}
        >
          Export to Excel
        </Button>
        <Box style={{ flexGrow: 2 }} />
        <SelectField
          style={{ flexGrow: 1 }}
          hasProjects
          showCount="project"
          fieldId={fieldFilter}
          onChange={(value) => setFieldFilter(value)}
        />
        &nbsp;&nbsp;
        <SelectReservoir
          style={{ flexGrow: 1 }}
          hasProjects
          showCount="project"
          reservoirId={reservoirFilter}
          onChange={(value) => setReservoirFilter(value)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" padding="checkbox">
          <TableHead>
            <TableRow key="headerrow">
              <TableHeaderCell
                title="Code"
                order={order}
                orderAsc={ProjectOrder.CODE_ASC}
                orderDesc={ProjectOrder.CODE_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Name"
                order={order}
                orderAsc={ProjectOrder.NAME_ASC}
                orderDesc={ProjectOrder.NAME_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="As Of Date"
                order={order}
                orderAsc={ProjectOrder.ASOFDATE_ASC}
                orderDesc={ProjectOrder.ASOFDATE_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Division"
                order={order}
                orderAsc={ProjectOrder.DIVISION_ASC}
                orderDesc={ProjectOrder.DIVISION_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Project Leader"
                order={order}
                orderAsc={ProjectOrder.PROJLEADER_ASC}
                orderDesc={ProjectOrder.PROJLEADER_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Project Coordinator"
                order={order}
                orderAsc={ProjectOrder.PROJCOORDINATOR_ASC}
                orderDesc={ProjectOrder.PROJCOORDINATOR_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Tech Coordinator"
                order={order}
                orderAsc={ProjectOrder.TECHCOORDINATOR_ASC}
                orderDesc={ProjectOrder.TECHCOORDINATOR_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Client Name"
                order={order}
                orderAsc={ProjectOrder.CLIENTNAME_ASC}
                orderDesc={ProjectOrder.CLIENTNAME_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Work Types"
                order={order}
                orderAsc={ProjectOrder.WORKTYPES_ASC}
                orderDesc={ProjectOrder.WORKTYPES_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Eval Type"
                order={order}
                orderAsc={ProjectOrder.EVALTYPE_ASC}
                orderDesc={ProjectOrder.EVALTYPE_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Guideline"
                order={order}
                orderAsc={ProjectOrder.GUIDELINE_ASC}
                orderDesc={ProjectOrder.GUIDELINE_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableCell>Countries</TableCell>
              <TableCell>Fields</TableCell>
              <TableCell>Reservoirs</TableCell>
              <TableCell />
            </TableRow>
            <TableRow key="filterrow">
              <TableFilterCell
                value={codeFilter}
                onChange={(v) => setCodeFilter(v)}
              />
              <TableFilterCell
                value={nameFilter}
                onChange={(v) => setNameFilter(v)}
              />
              <TableDateCell
                from={asOfDateFrom}
                to={asOfDateTo}
                onFromChange={(d) => setAsOfDateFrom(d)}
                onToChange={(d) => setAsOfDateTo(d)}
              />
              <TableFilterCell
                value={divisionFilter}
                onChange={(v) => setDivisionFilter(v)}
              />
              <TableFilterCell
                value={projLeaderFilter}
                onChange={(v) => setProjLeaderFilter(v)}
              />
              <TableFilterCell
                value={projCoordinatorFilter}
                onChange={(v) => setProjCoordinatorFilter(v)}
              />
              <TableFilterCell
                value={techCoordinatorFilter}
                onChange={(v) => setTechCoordinatorFilter(v)}
              />
              <TableFilterCell
                value={clientNameFilter}
                onChange={(v) => setClientNameFilter(v)}
              />
              <TableSelectCell
                options={workTypes}
                value={workTypeFilter}
                onChange={(v) => setWorkTypeFilter(v)}
              />
              <TableSelectCell
                options={evalTypes}
                value={evalTypeFilter}
                onChange={(v) => setEvalTypeFilter(v)}
              />
              <TableSelectCell
                options={guidelines}
                value={guidelineFilter}
                onChange={(v) => setGuidelineFilter(v)}
              />
              <TableCell>
                <SelectCountry
                  countryCode={countryFilter}
                  onChange={(v) => setCountryFilter(v)}
                />
              </TableCell>
              <TableCell>
                <Select
                  fullWidth
                  value={nullableBooleanToString(hasFields)}
                  onChange={(e) =>
                    setHasFields(
                      nullableBooleanFromString(e.target.value as string)
                    )
                  }
                >
                  <MenuItem value="undefined">All</MenuItem>
                  <MenuItem value="true">With Fields</MenuItem>
                  <MenuItem value="false">Without Fields</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  fullWidth
                  value={nullableBooleanToString(hasReservoirs)}
                  onChange={(e) =>
                    setHasReservoirs(
                      nullableBooleanFromString(e.target.value as string)
                    )
                  }
                >
                  <MenuItem value="undefined">All</MenuItem>
                  <MenuItem value="true">With Reservoirs</MenuItem>
                  <MenuItem value="false">Without Reservoirs</MenuItem>
                </Select>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((row) => (
              <TableRow
                key={row.id + row.code}
                style={{ ...(row.id ? {} : { backgroundColor: "lightgray" }) }}
              >
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{formatDateOnly(row.asOfDate)}</TableCell>
                <TableCell>{row.division}</TableCell>
                <TableCell>{row.projLeader}</TableCell>
                <TableCell>{row.projCoordinator}</TableCell>
                <TableCell>{row.techCoordinator}</TableCell>
                <TableCell>{row.clientName}</TableCell>
                <TableCell>{row.workTypes}</TableCell>
                <TableCell>{row.evalType}</TableCell>
                <TableCell>{row.guideline}</TableCell>
                <TableCell>{row.countries}</TableCell>
                <TableCell>
                  <Crosslink
                    to={"/fields?projectNo=" + row.code}
                    count={row.fieldCount}
                  />
                </TableCell>
                <TableCell>
                  <Crosslink
                    to={"/reservoirs?projectNo=" + row.code}
                    count={row.reservoirCount}
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSelectedProject(row);
                      setIsInfoDialogOpen(true);
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSelectedProject(row);
                      setIsReportsDialogOpen(true);
                    }}
                  >
                    <ListIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(_, p) => setPage(p)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(Number(e.target.value));
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ProjectInfoDialog
        open={isInfoDialogOpen}
        project={selectedProject}
        onClose={() => setIsInfoDialogOpen(false)}
      />
      <ProjectReportsDialog
        open={isReportsDialogOpen}
        projectNo={selectedProject.code}
        onClose={() => setIsReportsDialogOpen(false)}
      />
    </Container>
  );
}
