import { Typography } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import { ReactElement } from "react";
import Flag from "react-world-flags";
import { Basin, Country, Field, Reservoir } from "../../../types";
import BasinNodes from "./BasinNodes";

interface CountryNodeProps {
  country: Country;
  countryBasins: { [coCode: string]: Basin[] | undefined };
  basinFields: { [barCode: number]: Field[] | undefined };
  basinReservoirs: { [barCode: number]: Reservoir[] | undefined };
  checkedFieldIds: Set<number>;
  checkedReservoirIds: Set<number>;
  onFieldCheckedChanged: (fieldId: number, checked: boolean) => void;
  onReservoirCheckedChanged: (reservoirId: number, checked: boolean) => void;
  loadingNodes: string[];
  editingMode: boolean;
}

export default function CountryNode(props: CountryNodeProps): ReactElement {
  const nodeId: string = "CNT_" + props.country.code;
  const isLoading: boolean = props.loadingNodes.some((x) => x === nodeId);

  const barCount: number = props.editingMode
    ? props.country.basinAreaRegionCount
    : props.country.projectBasinAreaRegionCount;
  const fieldCount: number = props.editingMode
    ? props.country.fieldCount
    : props.country.projectFieldCount;
  const reservoirCount: number = props.editingMode
    ? props.country.reservoirCount
    : props.country.projectReservoirCount;

  return (
    <TreeItem
      nodeId={nodeId}
      key={nodeId}
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 0,
          }}
        >
          <Flag
            code={props.country.code}
            height="16"
            width="24"
            style={{ marginRight: "1rem" }}
          />
          <Typography variant="body2" component="span">
            {props.country.name}
          </Typography>
          &nbsp;
          <Typography variant="body2" component="span" color="textSecondary">
            {barCount}
            &nbsp;
            {fieldCount}
            &nbsp;
            {reservoirCount}
          </Typography>
        </div>
      }
    >
      {isLoading && (
        <Typography variant="body2" color="textSecondary">
          Loading ...
        </Typography>
      )}

      {barCount > 0 ? (
        <BasinNodes
          country={props.country}
          countryBasins={props.countryBasins}
          basinFields={props.basinFields}
          basinReservoirs={props.basinReservoirs}
          checkedFieldIds={props.checkedFieldIds}
          checkedReservoirIds={props.checkedReservoirIds}
          onFieldCheckedChanged={props.onFieldCheckedChanged}
          onReservoirCheckedChanged={props.onReservoirCheckedChanged}
          loadingNodes={props.loadingNodes}
          editingMode={props.editingMode}
        />
      ) : (
        <Typography variant="caption" component="span" style={{ opacity: 0.5 }}>
          No properties selected
        </Typography>
      )}
    </TreeItem>
  );
}
