import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ReactElement, useState } from "react";
import {
  apiExportProjectFields,
  apiExportProjectReservoirs,
  apiGetProjectPropertyCount,
} from "../api";

interface ProjectPropertiesExportDialogProps {
  projectCode: string;
  projectName: string;
  open: boolean;
  onClose: (confirm: boolean) => void;
}

export default function ProjectPropertiesExportDialog(
  props: ProjectPropertiesExportDialogProps
): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [exportPropertyType, setExportPropertyType] = useState(1);

  const exportProperties = () => {
    const forFields: boolean = exportPropertyType === 1 ? true : false;

    setIsLoading(true);
    apiGetProjectPropertyCount(props.projectCode, forFields)
      .then((data) => {
        console.log(data);

        setIsLoading(false);
        setErrorMessage("");

        if (data === 0) {
          if (forFields) {
            setErrorMessage("Project contains no fields.");
          } else {
            setErrorMessage("Project contains no reservoirs.");
          }
          return;
        }

        if (forFields) {
          apiExportProjectFields(props.projectCode);
        } else {
          apiExportProjectReservoirs(props.projectCode);
        }

        props.onClose(true);
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage("Error while trying to export properties.");
      });
  };

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>
        Export Properties for Project {props.projectCode} {props.projectName}
      </DialogTitle>
      <DialogContent dividers>
        <Backdrop open={isLoading} style={{ zIndex: 100 }}>
          <CircularProgress />
        </Backdrop>
        <FormControl fullWidth>
          <InputLabel id="upload-excel-type">Property Type</InputLabel>
          <Select
            labelId="upload-excel-type"
            fullWidth
            value={exportPropertyType}
            onChange={(e) => setExportPropertyType(e.target.value as number)}
          >
            <MenuItem value={1}>Fields</MenuItem>
            <MenuItem value={2}>Reservoirs</MenuItem>
          </Select>
        </FormControl>

        {errorMessage && (
          <Alert variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={() => props.onClose(false)}>
          Cancel
        </Button>
        <span style={{ padding: "0.2rem" }}>&nbsp;</span>
        <Button color="primary" autoFocus onClick={exportProperties}>
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
}
