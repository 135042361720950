import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { ReactElement } from "react";
import { apiDeleteProjectDocument } from "../api";

interface ProjectReportRemoveDialogProps {
  documentId: number;
  documentName: string;
  open: boolean;
  onClose: (confirm: boolean) => void;
}

export default function ProjectReportRemoveDialog(
  props: ProjectReportRemoveDialogProps
): ReactElement {
  const save = () => {
    apiDeleteProjectDocument(props.documentId)
      .then(() => {
        props.onClose(true);
      })
      .catch((e) => {
        console.log("Error deleting project report: " + e.message);
      });
  };

  const cancel = () => {
    props.onClose(false);
  };

  return (
    <Dialog open={props.open} fullWidth maxWidth="sm">
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent dividers>
        <Typography>
          Please confirm you want to delete the following report:
        </Typography>
        <Typography>
          <strong>{props.documentName}</strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={cancel}>
          Cancel
        </Button>
        <Button color="primary" autoFocus onClick={save}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
