import { Popper, PopperProps, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CSSProperties, ReactElement, useEffect, useState } from "react";
import Flag from "react-world-flags";
import { apiGetCountries } from "../api";
import { Country } from "../types";

interface SelectCountryProps {
  style?: CSSProperties;
  countryCode: string;
  onChange: (countryCode: string) => void;
}

function CustomPopper(props: PopperProps) {
  return <Popper {...props} style={{ width: "fit-content" }} />;
}

export default function SelectCountry(props: SelectCountryProps): ReactElement {
  const [countries, setCountries] = useState<Country[]>([]);
  const [selected, setSelected] = useState<Country | null>(null);

  useEffect(() => {
    apiGetCountries({}).then((data) => setCountries(data));
  }, []);

  useEffect(() => {
    setSelected(countries.find((x) => x.code === props.countryCode) || null);
  }, [props.countryCode, countries]);

  return (
    <Autocomplete
      size="small"
      options={countries}
      value={selected}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <>
          <Flag
            code={option.code}
            width="30"
            height="20"
            style={{ marginRight: "0.5rem" }}
          />
          <Typography variant="caption">{option.name}</Typography>
        </>
      )}
      renderInput={(params) => <TextField {...params} />}
      onChange={(_, value) => {
        setSelected(value);
        props.onChange(value?.code || "");
      }}
      PopperComponent={CustomPopper}
    />
  );
}
