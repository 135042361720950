import {
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ReactElement, useEffect, useState } from "react";
import { useScreenHeight } from "../hooks";
import { ProjectInfo } from "../types";
import TablePaginationActions from "./TablePaginationActions";

interface ProjectListProps {
  hidden?: boolean;
  projects: ProjectInfo[];
  projectCode: string;
  onChange: (projectCode: string) => void;
}

export default function ProjectList(props: ProjectListProps): ReactElement {
  const [filter, setFilter] = useState("");

  const [filteredProjects, setFilteredProjects] = useState<ProjectInfo[]>([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const screenHeight = useScreenHeight();

  useEffect(() => {
    setFilteredProjects(
      props.projects.filter(
        (e) =>
          e.id.includes(filter) ||
          e.name.toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [props.projects, filter]);

  useEffect(() => {
    const index = filteredProjects.findIndex((p) => p.id === props.projectCode);
    if (index >= 0) {
      setPage(Math.floor(index / rowsPerPage));
    } else {
      const lastIndex = Math.max(0, filteredProjects.length - 1);
      const lastPage = Math.floor(lastIndex / rowsPerPage);
      setPage((p) => Math.min(p, lastPage));
    }
  }, [props.projectCode, filteredProjects, rowsPerPage]);

  if (props.hidden) return <></>;

  return (
    <>
      <Box m={2}>
        <TextField
          fullWidth
          label="Filter projects"
          size="small"
          variant="outlined"
          margin="dense"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box maxHeight={screenHeight - 330} style={{ overflow: "auto" }}>
        <List dense>
          {filteredProjects
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((project) => (
              <ListItem
                button
                key={project.id}
                selected={project.id === props.projectCode}
                onClick={() => props.onChange(project.id)}
              >
                <ListItemText
                  style={{ opacity: project.hasProperties ? 1.0 : 0.5 }}
                >
                  <strong>{project.id}</strong> {project.name}
                </ListItemText>
              </ListItem>
            ))}
        </List>
      </Box>

      <TablePagination
        component="div"
        rowsPerPageOptions={[]}
        count={filteredProjects.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(_, p) => setPage(p)}
        onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}
