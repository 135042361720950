import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { ReactElement, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { msalInstance } from "../auth";
import { useLocalStorage } from "../hooks";
import getTheme from "../theme";
import Fields from "./Fields";
import Header from "./Header";
import LegacySearch from "./LegacySearch";
import Login from "./Login";
import MainForm from "./MainForm";
import Projects from "./Projects";
import Reservoirs from "./Reservoirs";
import ThemeChooserDialog from "./ThemeChooserDialog";
import Unauthorized from "./Unauthorized";
import Users from "./Users";

export default function Application(): ReactElement {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [theme, setTheme] = useLocalStorage("app.theme", "demac");
  const [themeDialogOpen, setThemeDialogOpen] = useState(false);

  const getContent = () => {
    if (isLocked) {
      return (
        <Unauthorized onLogoutClick={() => msalInstance.logoutRedirect()} />
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<MainForm isAdmin={isAdmin} />} />
          <Route path="/search" element={<LegacySearch />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/fields" element={<Fields />} />
          <Route path="/reservoirs" element={<Reservoirs />} />
          <Route path="/users" element={<Users />} />
        </Routes>
      );
    }
  };

  return (
    <MuiThemeProvider theme={getTheme(theme)}>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Header
            onCurrentUserInfoLoaded={(data) => {
              setIsAdmin(data.isAdmin);
              setIsLocked(data.isLocked);
            }}
            onChangeColorSchemeClick={() => setThemeDialogOpen(true)}
            onLogoutClick={() => msalInstance.logoutRedirect()}
          />

          <UnauthenticatedTemplate>
            <Login onLoginClick={() => msalInstance.loginRedirect()} />
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>{getContent()}</AuthenticatedTemplate>

          <ThemeChooserDialog
            open={themeDialogOpen}
            value={theme}
            onConfirm={(newTheme) => {
              setTheme(newTheme);
              setThemeDialogOpen(false);
            }}
            onDismiss={() => {
              setThemeDialogOpen(false);
            }}
          />
        </BrowserRouter>
      </MsalProvider>
    </MuiThemeProvider>
  );
}
