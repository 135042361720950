import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Alert } from "@material-ui/lab";
import { ReactElement, useEffect, useState } from "react";
import {
  apiExportFieldsAsCsv,
  apiExportFieldsAsExcel,
  apiGetFieldCount,
  apiGetFields,
} from "../api";
import {
  useSearchParam,
  useSearchParamBoolean,
  useSearchParamNumber,
} from "../hooks";
import { EmptyField, Field, FieldOrder } from "../types";
import {
  nullableBooleanFromString,
  nullableBooleanToString,
  saveBlob,
} from "../utils";
import Crosslink from "./Crosslink";
import FieldInfoDialog from "./FieldInfoDialog";
import SelectCountry from "./SelectCountry";
import SelectProject from "./SelectProject";
import TableFilterCell from "./TableFilterCell";
import TableHeaderCell from "./TableHeaderCell";
import TablePaginationActions from "./TablePaginationActions";

export default function Fields(): ReactElement {
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [codeFilter, setCodeFilter] = useSearchParam("code", "");
  const [nameFilter, setNameFilter] = useSearchParam("name", "");
  const [aliasFilter, setAliasFilter] = useSearchParam("alias", "");
  const [barNameFilter, setBarNameFilter] = useSearchParam("barName", "");
  const [locationFilter, setLocationFilter] = useSearchParam("location", "");
  const [countryFilter, setCountryFilter] = useSearchParam("countryCode", "");
  const [projectFilter, setProjectFilter] = useSearchParam("projectNo", "");
  const [hasProjects, setHasProjects] = useSearchParamBoolean("hasProjects");
  const [includeDeleted, setIncludeDeleted] = useSearchParam("deleted", false);

  const [order, setOrder] = useSearchParam<FieldOrder>(
    "order",
    FieldOrder.ID_ASC
  );

  const [count, setCount] = useState(0);
  const [page, setPage] = useSearchParamNumber("page", 0);
  const [rowsPerPage, setRowsPerPage] = useSearchParamNumber("rowsPerPage", 25);
  const [fields, setFields] = useState<Field[]>([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedField, setSelectedField] = useState<Field>(EmptyField);

  useEffect(() => {
    setIsLoadingCount(true);
    apiGetFieldCount({
      codeContains: codeFilter,
      nameContains: nameFilter,
      aliasContains: aliasFilter,
      barNameContains: barNameFilter,
      locationContains: locationFilter,
      countryCode: countryFilter,
      projectNo: projectFilter,
      hasProjects: hasProjects,
      includeDeleted: includeDeleted,
    })
      .then((data) => {
        setIsLoadingCount(false);
        setCount(data);
      })
      .catch((e) => {
        setIsLoadingCount(false);
        setErrorMessage(e.message);
      });
  }, [
    codeFilter,
    nameFilter,
    aliasFilter,
    barNameFilter,
    locationFilter,
    countryFilter,
    projectFilter,
    hasProjects,
    includeDeleted,
  ]);

  useEffect(() => {
    setIsLoadingFields(true);
    apiGetFields({
      codeContains: codeFilter,
      nameContains: nameFilter,
      aliasContains: aliasFilter,
      barNameContains: barNameFilter,
      locationContains: locationFilter,
      countryCode: countryFilter,
      projectNo: projectFilter,
      hasProjects: hasProjects,
      includeDeleted: includeDeleted,
      order: order,
      skip: page * rowsPerPage,
      take: rowsPerPage,
    })
      .then((data) => {
        setIsLoadingFields(false);
        setFields(data);
      })
      .catch((e) => {
        setIsLoadingFields(false);
        setErrorMessage(e.message);
      });
  }, [
    codeFilter,
    nameFilter,
    aliasFilter,
    barNameFilter,
    locationFilter,
    countryFilter,
    projectFilter,
    hasProjects,
    includeDeleted,
    order,
    page,
    rowsPerPage,
  ]);

  const handleExportCsv = () => {
    apiExportFieldsAsCsv({
      codeContains: codeFilter,
      nameContains: nameFilter,
      aliasContains: aliasFilter,
      barNameContains: barNameFilter,
      locationContains: locationFilter,
      countryCode: countryFilter,
      projectNo: projectFilter,
      hasProjects: hasProjects,
      includeDeleted: includeDeleted,
      order: order,
    })
      .then((blob) => saveBlob(blob, "Fields.csv"))
      .catch((e) => setErrorMessage(e.message));
  };

  const handleExportExcel = () => {
    apiExportFieldsAsExcel({
      codeContains: codeFilter,
      nameContains: nameFilter,
      aliasContains: aliasFilter,
      barNameContains: barNameFilter,
      locationContains: locationFilter,
      countryCode: countryFilter,
      projectNo: projectFilter,
      hasProjects: hasProjects,
      includeDeleted: includeDeleted,
      order: order,
    })
      .then((blob) => saveBlob(blob, "Fields.xlsx"))
      .catch((e) => setErrorMessage(e.message));
  };

  return (
    <Container
      component="main"
      maxWidth="xl"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Backdrop
        open={isLoadingCount || isLoadingFields}
        style={{ zIndex: 100 }}
      >
        <CircularProgress />
      </Backdrop>
      <Box mb={2} display="flex" alignItems="center">
        <Button
          color="primary"
          variant="contained"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={handleExportCsv}
        >
          Export to CSV
        </Button>
        &nbsp;&nbsp;
        <Button
          color="primary"
          variant="contained"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={handleExportExcel}
        >
          Export to Excel
        </Button>
        <Box style={{ flexGrow: 2 }} />
        <SelectProject
          style={{ flexGrow: 1 }}
          hasFields
          showCount="field"
          projectCode={projectFilter}
          onChange={(value) => setProjectFilter(value)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" padding="checkbox">
          <TableHead>
            <TableRow key="headerrow">
              <TableHeaderCell
                title="Code"
                order={order}
                orderAsc={FieldOrder.CODE_ASC}
                orderDesc={FieldOrder.CODE_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Name"
                order={order}
                orderAsc={FieldOrder.NAME_ASC}
                orderDesc={FieldOrder.NAME_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Alias"
                order={order}
                orderAsc={FieldOrder.ALIAS_ASC}
                orderDesc={FieldOrder.ALIAS_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Country"
                order={order}
                orderAsc={FieldOrder.COUNTRY_NAME_ASC}
                orderDesc={FieldOrder.COUNTRY_NAME_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="BAR Name"
                order={order}
                orderAsc={FieldOrder.BAR_NAME_ASC}
                orderDesc={FieldOrder.BAR_NAME_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Location"
                order={order}
                orderAsc={FieldOrder.LOCATION_ASC}
                orderDesc={FieldOrder.LOCATION_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableCell>Projects</TableCell>
              <TableHeaderCell
                title="Deleted"
                order={order}
                orderAsc={FieldOrder.IS_DELETED_ASC}
                orderDesc={FieldOrder.IS_DELETED_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableCell />
            </TableRow>
            <TableRow key="filterrow">
              <TableFilterCell
                value={codeFilter}
                onChange={(v) => setCodeFilter(v)}
              />
              <TableFilterCell
                value={nameFilter}
                onChange={(v) => setNameFilter(v)}
              />
              <TableFilterCell
                value={aliasFilter}
                onChange={(v) => setAliasFilter(v)}
              />
              <TableCell>
                <SelectCountry
                  countryCode={countryFilter}
                  onChange={(v) => setCountryFilter(v)}
                />
              </TableCell>
              <TableFilterCell
                value={barNameFilter}
                onChange={(v) => setBarNameFilter(v)}
              />
              <TableFilterCell
                value={locationFilter}
                onChange={(v) => setLocationFilter(v)}
              />
              <TableCell>
                <Select
                  fullWidth
                  value={nullableBooleanToString(hasProjects)}
                  onChange={(e) =>
                    setHasProjects(
                      nullableBooleanFromString(e.target.value as string)
                    )
                  }
                >
                  <MenuItem value="undefined">All</MenuItem>
                  <MenuItem value="true">With Projects</MenuItem>
                  <MenuItem value="false">Without Projects</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={includeDeleted}
                  onChange={(_, v) => setIncludeDeleted(v)}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.alias}</TableCell>
                <TableCell>{row.countryName}</TableCell>
                <TableCell>{row.barName}</TableCell>
                <TableCell>{row.location}</TableCell>
                <TableCell>
                  <Crosslink
                    to={"/projects?fieldId=" + row.id}
                    count={row.projectCount}
                  />
                </TableCell>
                <TableCell>{row.isDeleted ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSelectedField(row);
                      setIsDialogOpen(true);
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(_, p) => setPage(p)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(Number(e.target.value));
        }}
        ActionsComponent={TablePaginationActions}
      />
      <FieldInfoDialog
        open={isDialogOpen}
        field={selectedField}
        onClose={() => setIsDialogOpen(false)}
      />
    </Container>
  );
}
