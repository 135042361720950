import { Box, Typography } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import { ReactElement } from "react";
import { Basin, Country, Field, Reservoir } from "../../../types";
import FieldNodes from "./FieldNodes";
import ReservoirNodes from "./ReservoirNodes";

interface BasinNodesProps {
  country: Country;
  countryBasins: { [coCode: string]: Basin[] | undefined };
  basinFields: { [barCode: number]: Field[] | undefined };
  basinReservoirs: { [barCode: number]: Reservoir[] | undefined };
  checkedFieldIds: Set<number>;
  checkedReservoirIds: Set<number>;
  onFieldCheckedChanged: (fieldId: number, checked: boolean) => void;
  onReservoirCheckedChanged: (reservoirId: number, checked: boolean) => void;
  loadingNodes: string[];
  editingMode: boolean;
}

export default function BasinNodes(props: BasinNodesProps): ReactElement {
  const getBasinNodeId = (basin: Basin) => {
    return basin === undefined || basin.code === undefined
      ? Math.random().toString()
      : "BAS_" + basin.code.toString();
  };

  const countryBasins: Basin[] = props.countryBasins[props.country.code] || [];

  return (
    <>
      {countryBasins.map((basin) => (
        <TreeItem
          nodeId={getBasinNodeId(basin)}
          key={getBasinNodeId(basin)}
          label={
            <Box
              style={{
                opacity:
                  basin.fieldCount > 0 || basin.reservoirCount > 0 ? 1.0 : 0.5,
              }}
            >
              <Typography variant="body2" component="span">
                {basin.name}
              </Typography>
              &nbsp;
              <Typography
                variant="body2"
                component="span"
                color="textSecondary"
              >
                {basin.fieldCount}
                &nbsp;
                {basin.reservoirCount}
              </Typography>
            </Box>
          }
        >
          <FieldNodes
            basin={basin}
            basinFields={props.basinFields}
            checkedFieldIds={props.checkedFieldIds}
            onFieldCheckedChange={props.onFieldCheckedChanged}
            loadingNodes={props.loadingNodes}
            editingMode={props.editingMode}
          />

          <ReservoirNodes
            basin={basin}
            basinReservoirs={props.basinReservoirs}
            checkedReservoirIds={props.checkedReservoirIds}
            onReservoirCheckedChanged={props.onReservoirCheckedChanged}
            loadingNodes={props.loadingNodes}
            editingMode={props.editingMode}
          />
        </TreeItem>
      ))}
    </>
  );
}
