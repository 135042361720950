import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { apiGetDocumentsForProject, apiSetProjectDocuments } from "../api";
import TablePaginationActions from "./TablePaginationActions";

interface ProjectReportAddDialogProps {
  projectCode: string;
  projectName: string;
  open: boolean;
  onClose: (confirm: boolean) => void;
}

interface Item {
  name: string;
  checked: boolean;
}

export default function ProjectReportAddDialog(
  props: ProjectReportAddDialogProps
): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [documents, setDocuments] = useState<Item[]>([]);

  const [documentFilter, setDocumentFilter] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  useEffect(() => {
    if (!props.open) return;

    setIsLoading(true);
    apiGetDocumentsForProject(props.projectCode)
      .then((data) => {
        setIsLoading(false);
        setErrorMessage("");
        setDocuments(
          data.allDocuments.map((d) => ({
            name: d,
            checked:
              d.startsWith(props.projectCode + " ") ||
              data.selectedDocuments.filter((e) => e === d).length > 0,
          }))
        );
        setDocumentFilter("");
        setPage(0);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(
          "Failed to load list of documents from server. Please check VPN settings." +
            e.message
        );
        setDocuments([]);
      });
  }, [props.projectCode, props.open]);

  const selectDocument = (e: ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    const checked = e.target.checked;

    setDocuments(
      documents.map((d) => (d.name === id ? { ...d, checked: checked } : d))
    );
  };

  const save = () => {
    const selectedDocumentNames: string[] = documents
      .filter((d) => d.checked)
      .map((d) => d.name);

    setIsStoring(true);
    apiSetProjectDocuments({
      projectId: props.projectCode,
      documentNames: selectedDocumentNames,
    })
      .then(() => {
        setIsStoring(false);
        setErrorMessage("");

        props.onClose(true);
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  const cancel = () => {
    props.onClose(false);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Add Reports for Project {props.projectCode} {props.projectName}
      </DialogTitle>
      <DialogContent dividers>
        <Backdrop open={isLoading || isStoring} style={{ zIndex: 100 }}>
          <CircularProgress />
        </Backdrop>
        {errorMessage ? (
          <Alert severity="error" variant="outlined">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        ) : (
          <>
            <TextField
              fullWidth
              label="Filter documents"
              size="small"
              variant="outlined"
              margin="normal"
              value={documentFilter}
              onChange={(e) => {
                setDocumentFilter(e.target.value);
                setPage(0);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Table stickyHeader size="small">
              <TableBody>
                {documents
                  .filter((e) =>
                    e.name.toLowerCase().includes(documentFilter.toLowerCase())
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((document) => (
                    <TableRow key={document.name}>
                      <TableCell>
                        <Checkbox
                          id={document.name}
                          checked={document.checked}
                          onChange={selectDocument}
                          style={{
                            padding: "0",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption" component="span">
                          {document.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              rowsPerPageOptions={[15]}
              count={
                documents.filter((e) =>
                  e.name.toLowerCase().includes(documentFilter.toLowerCase())
                ).length
              }
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={(_, p) => setPage(p)}
              onRowsPerPageChange={(e) =>
                setRowsPerPage(Number(e.target.value))
              }
              ActionsComponent={TablePaginationActions}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={cancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={save} autoFocus>
          Add Reports
        </Button>
      </DialogActions>
    </Dialog>
  );
}
