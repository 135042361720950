import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Alert } from "@material-ui/lab";
import { ReactElement, useEffect, useState } from "react";
import {
  apiExportReservoirsAsCsv,
  apiExportReservoirsAsExcel,
  apiGetReservoirCount,
  apiGetReservoirs,
} from "../api";
import {
  useSearchParam,
  useSearchParamBoolean,
  useSearchParamNumber,
} from "../hooks";
import { EmptyReservoir, Reservoir, ReservoirOrder } from "../types";
import {
  nullableBooleanFromString,
  nullableBooleanToString,
  saveBlob,
} from "../utils";
import Crosslink from "./Crosslink";
import ReservoirInfoDialog from "./ReservoirInfoDialog";
import SelectCountry from "./SelectCountry";
import SelectProject from "./SelectProject";
import TableFilterCell from "./TableFilterCell";
import TableHeaderCell from "./TableHeaderCell";
import TablePaginationActions from "./TablePaginationActions";

export default function Reservoirs(): ReactElement {
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [codeFilter, setCodeFilter] = useSearchParam("code", "");
  const [nameFilter, setNameFilter] = useSearchParam("name", "");
  const [aliasFilter, setAliasFilter] = useSearchParam("alias", "");
  const [barNameFilter, setBarNameFilter] = useSearchParam("barName", "");
  const [eraFilter, setEraFilter] = useSearchParam("era", "");
  const [lithologyFilter, setLithologyFilter] = useSearchParam("lithology", "");
  const [periodFilter, setPeriodFilter] = useSearchParam("period", "");
  const [productFilter, setProductFilter] = useSearchParam("product", "");
  const [countryFilter, setCountryFilter] = useSearchParam("countryCode", "");
  const [projectFilter, setProjectFilter] = useSearchParam("projectNo", "");
  const [hasProjects, setHasProjects] = useSearchParamBoolean("hasProjects");
  const [includeDeleted, setIncludeDeleted] = useSearchParam("deleted", false);

  const [order, setOrder] = useSearchParam<ReservoirOrder>(
    "order",
    ReservoirOrder.ID_ASC
  );

  const [count, setCount] = useState(0);
  const [page, setPage] = useSearchParamNumber("page", 0);
  const [rowsPerPage, setRowsPerPage] = useSearchParamNumber("rowsPerPage", 25);
  const [reservoirs, setReservoirs] = useState<Reservoir[]>([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedReservoir, setSelectedReservoir] =
    useState<Reservoir>(EmptyReservoir);

  useEffect(() => {
    setIsLoadingCount(true);
    apiGetReservoirCount({
      codeContains: codeFilter,
      nameContains: nameFilter,
      aliasContains: aliasFilter,
      barNameContains: barNameFilter,
      eraContains: eraFilter,
      lithologyContains: lithologyFilter,
      periodContains: periodFilter,
      productContains: productFilter,
      countryCode: countryFilter,
      projectNo: projectFilter,
      hasProjects: hasProjects,
      includeDeleted: includeDeleted,
    })
      .then((data) => {
        setIsLoadingCount(false);
        setCount(data);
      })
      .catch((e) => {
        setIsLoadingCount(false);
        setErrorMessage(e.message);
      });
  }, [
    codeFilter,
    nameFilter,
    aliasFilter,
    barNameFilter,
    eraFilter,
    lithologyFilter,
    periodFilter,
    productFilter,
    countryFilter,
    projectFilter,
    hasProjects,
    includeDeleted,
  ]);

  useEffect(() => {
    setIsLoadingFields(true);
    apiGetReservoirs({
      codeContains: codeFilter,
      nameContains: nameFilter,
      aliasContains: aliasFilter,
      barNameContains: barNameFilter,
      eraContains: eraFilter,
      lithologyContains: lithologyFilter,
      periodContains: periodFilter,
      productContains: productFilter,
      countryCode: countryFilter,
      projectNo: projectFilter,
      hasProjects: hasProjects,
      includeDeleted: includeDeleted,
      order: order,
      skip: page * rowsPerPage,
      take: rowsPerPage,
    })
      .then((data) => {
        setIsLoadingFields(false);
        setReservoirs(data);
      })
      .catch((e) => {
        setIsLoadingFields(false);
        setErrorMessage(e.message);
      });
  }, [
    codeFilter,
    nameFilter,
    aliasFilter,
    barNameFilter,
    eraFilter,
    lithologyFilter,
    periodFilter,
    productFilter,
    countryFilter,
    projectFilter,
    hasProjects,
    includeDeleted,
    order,
    page,
    rowsPerPage,
  ]);

  const handleExportCsv = () => {
    apiExportReservoirsAsCsv({
      codeContains: codeFilter,
      nameContains: nameFilter,
      aliasContains: aliasFilter,
      barNameContains: barNameFilter,
      eraContains: eraFilter,
      lithologyContains: lithologyFilter,
      periodContains: periodFilter,
      productContains: productFilter,
      countryCode: countryFilter,
      projectNo: projectFilter,
      hasProjects: hasProjects,
      includeDeleted: includeDeleted,
      order: order,
    })
      .then((blob) => saveBlob(blob, "Reservoirs.csv"))
      .catch((e) => setErrorMessage(e.message));
  };

  const handleExportExcel = () => {
    apiExportReservoirsAsExcel({
      codeContains: codeFilter,
      nameContains: nameFilter,
      aliasContains: aliasFilter,
      barNameContains: barNameFilter,
      eraContains: eraFilter,
      lithologyContains: lithologyFilter,
      periodContains: periodFilter,
      productContains: productFilter,
      countryCode: countryFilter,
      projectNo: projectFilter,
      hasProjects: hasProjects,
      includeDeleted: includeDeleted,
      order: order,
    })
      .then((blob) => saveBlob(blob, "Reservoirs.xlsx"))
      .catch((e) => setErrorMessage(e.message));
  };

  return (
    <Container
      component="main"
      maxWidth="xl"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Backdrop
        open={isLoadingCount || isLoadingFields}
        style={{ zIndex: 100 }}
      >
        <CircularProgress />
      </Backdrop>
      <Box mb={2} display="flex" alignItems="center">
        <Button
          color="primary"
          variant="contained"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={handleExportCsv}
        >
          Export to CSV
        </Button>
        &nbsp;&nbsp;
        <Button
          color="primary"
          variant="contained"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={handleExportExcel}
        >
          Export to Excel
        </Button>
        <Box style={{ flexGrow: 2 }} />
        <SelectProject
          style={{ flexGrow: 1 }}
          hasReservoirs
          showCount="reservoir"
          projectCode={projectFilter}
          onChange={(value) => setProjectFilter(value)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" padding="checkbox">
          <TableHead>
            <TableRow key="headerrow">
              <TableHeaderCell
                title="Code"
                order={order}
                orderAsc={ReservoirOrder.CODE_ASC}
                orderDesc={ReservoirOrder.CODE_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Name"
                order={order}
                orderAsc={ReservoirOrder.NAME_ASC}
                orderDesc={ReservoirOrder.NAME_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Alias"
                order={order}
                orderAsc={ReservoirOrder.ALIAS_ASC}
                orderDesc={ReservoirOrder.ALIAS_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Country"
                order={order}
                orderAsc={ReservoirOrder.COUNTRY_NAME_ASC}
                orderDesc={ReservoirOrder.COUNTRY_NAME_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="BAR Name"
                order={order}
                orderAsc={ReservoirOrder.BAR_NAME_ASC}
                orderDesc={ReservoirOrder.BAR_NAME_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Era"
                order={order}
                orderAsc={ReservoirOrder.ERA_ASC}
                orderDesc={ReservoirOrder.ERA_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Lithology"
                order={order}
                orderAsc={ReservoirOrder.LITHOLOGY_ASC}
                orderDesc={ReservoirOrder.LITHOLOGY_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Period"
                order={order}
                orderAsc={ReservoirOrder.PERIOD_ASC}
                orderDesc={ReservoirOrder.PERIOD_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Product"
                order={order}
                orderAsc={ReservoirOrder.PRODUCT_ASC}
                orderDesc={ReservoirOrder.PRODUCT_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableCell>Projects</TableCell>
              <TableHeaderCell
                title="Deleted"
                order={order}
                orderAsc={ReservoirOrder.IS_DELETED_ASC}
                orderDesc={ReservoirOrder.IS_DELETED_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableCell />
            </TableRow>
            <TableRow key="filterrow">
              <TableFilterCell
                value={codeFilter}
                onChange={(v) => setCodeFilter(v)}
              />
              <TableFilterCell
                value={nameFilter}
                onChange={(v) => setNameFilter(v)}
              />
              <TableFilterCell
                value={aliasFilter}
                onChange={(v) => setAliasFilter(v)}
              />
              <TableCell>
                <SelectCountry
                  countryCode={countryFilter}
                  onChange={(v) => setCountryFilter(v)}
                />
              </TableCell>
              <TableFilterCell
                value={barNameFilter}
                onChange={(v) => setBarNameFilter(v)}
              />
              <TableFilterCell
                value={eraFilter}
                onChange={(v) => setEraFilter(v)}
              />
              <TableFilterCell
                value={lithologyFilter}
                onChange={(v) => setLithologyFilter(v)}
              />
              <TableFilterCell
                value={periodFilter}
                onChange={(v) => setPeriodFilter(v)}
              />
              <TableFilterCell
                value={productFilter}
                onChange={(v) => setProductFilter(v)}
              />
              <TableCell>
                <Select
                  fullWidth
                  value={nullableBooleanToString(hasProjects)}
                  onChange={(e) =>
                    setHasProjects(
                      nullableBooleanFromString(e.target.value as string)
                    )
                  }
                >
                  <MenuItem value="undefined">All</MenuItem>
                  <MenuItem value="true">With Projects</MenuItem>
                  <MenuItem value="false">Without Projects</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={includeDeleted}
                  onChange={(_, v) => setIncludeDeleted(v)}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {reservoirs.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.alias}</TableCell>
                <TableCell>{row.countryName}</TableCell>
                <TableCell>{row.barName}</TableCell>
                <TableCell>{row.era}</TableCell>
                <TableCell>{row.lithology}</TableCell>
                <TableCell>{row.period}</TableCell>
                <TableCell>{row.product}</TableCell>
                <TableCell>
                  <Crosslink
                    to={"/projects?reservoirId=" + row.id}
                    count={row.projectCount}
                  />
                </TableCell>
                <TableCell>{row.isDeleted ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSelectedReservoir(row);
                      setIsDialogOpen(true);
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(_, p) => setPage(p)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(Number(e.target.value));
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ReservoirInfoDialog
        open={isDialogOpen}
        reservoir={selectedReservoir}
        onClose={() => setIsDialogOpen(false)}
      />
    </Container>
  );
}
