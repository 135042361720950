import { getAccessToken } from "./auth";
import {
  Basin,
  Client,
  Country,
  CurrentUser,
  EvalType,
  Field,
  FieldOrder,
  Guideline,
  Project,
  ProjectField,
  ProjectInfo,
  ProjectOrder,
  ProjectReservoir,
  Report,
  Reservoir,
  ReservoirOrder,
  User,
  UserActionResult,
  WorkType,
} from "./types";

const ApiPrefix: string = process.env.REACT_APP_API_URL_PREFIX || "";

type Params = Record<string, string | string[]>;

function formUrl(path: string, params?: Params): string {
  const url: URL = new URL(path, ApiPrefix);
  for (const name in params) {
    const value = params[name];
    if (typeof value === "string") {
      url.searchParams.append(name, value);
    } else {
      for (const item of value) {
        url.searchParams.append(name, item);
      }
    }
  }
  return url.toString();
}

interface ApiCallParams {
  method: string;
  path: string;
  body?: object;
  params?: Params;
}

async function apiCallInner(params: ApiCallParams): Promise<Response> {
  const url: string = formUrl(params.path, params.params);
  const accessToken: string = await getAccessToken();
  const response: Response = await fetch(url, {
    method: params.method,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params.body),
  });
  return response;
}

export async function apiGetCurrentUser(): Promise<CurrentUser> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "currentuser",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: CurrentUser = await response.json();
  return data;
}

interface GetBasinsAreasRegionsArgs {
  countryCode?: string;
  projectCode?: string;
  fieldOrReservoirCodeOrNameContains?: string;
  hasFieldsOrReservoirs?: boolean;
}

export async function apiGetBasinsAreasRegions(
  args: GetBasinsAreasRegionsArgs
): Promise<Basin[]> {
  const params: Params = {};
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.projectCode) {
    params["projectCode"] = args.projectCode;
  }
  if (args.fieldOrReservoirCodeOrNameContains) {
    params["fieldOrReservoirCodeOrNameContains"] =
      args.fieldOrReservoirCodeOrNameContains;
  }
  if (typeof args.hasFieldsOrReservoirs !== "undefined") {
    params["hasFieldsOrReservoirs"] = args.hasFieldsOrReservoirs
      ? "true"
      : "false";
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "basinsareasregions",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Basin[] = await response.json();
  return data;
}

export async function apiGetClients(): Promise<Client[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "clients/all",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Client[] = await response.json();
  return data;
}

export async function apiGetClientsForCountry(
  country: string
): Promise<Client[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "clients/countryclients",
    params: { country },
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Client[] = await response.json();
  return data;
}

interface GetCountriesArgs {
  clientCode?: string;
  projectCode?: string;
  fieldOrReservoirCodeOrNameContains?: string;
  hasBasinsAreasRegions?: boolean;
}

export async function apiGetCountries(
  args: GetCountriesArgs
): Promise<Country[]> {
  const params: Params = {};
  if (args.clientCode) {
    params["clientCode"] = args.clientCode;
  }
  if (args.projectCode) {
    params["projectCode"] = args.projectCode;
  }
  if (args.fieldOrReservoirCodeOrNameContains) {
    params["fieldOrReservoirCodeOrNameContains"] =
      args.fieldOrReservoirCodeOrNameContains;
  }
  if (typeof args.hasBasinsAreasRegions !== "undefined") {
    params["hasBasinsAreasRegions"] = args.hasBasinsAreasRegions
      ? "true"
      : "false";
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "countries",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Country[] = await response.json();
  return data;
}

export async function apiGetEvalTypes(): Promise<EvalType[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "projects/evaltypes",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: EvalType[] = await response.json();
  return data;
}

export async function apiGetGuidelines(): Promise<Guideline[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "projects/guidelines",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Guideline[] = await response.json();
  return data;
}

export async function apiGetWorkTypes(): Promise<WorkType[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "projects/worktypes",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: WorkType[] = await response.json();
  return data;
}

export async function apiGetProjectFields(
  projectId: string
): Promise<ProjectField[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "projectFields/list",
    params: { projectId },
  });
  if (!response.ok) throw Error(response.statusText);
  const data: ProjectField[] = await response.json();
  return data;
}

export async function apiGetProjectReservoirs(
  projectId: string
): Promise<ProjectReservoir[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "projectReservoirs/list",
    params: { projectId },
  });
  if (!response.ok) throw Error(response.statusText);
  const data: ProjectReservoir[] = await response.json();
  return data;
}

export async function apiSaveProjectProperties(
  projectId: string,
  fieldIds: number[],
  reservoirIds: number[]
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "PUT",
    path: "projectProperties",
    body: { projectId: projectId, fields: fieldIds, reservoirs: reservoirIds },
  });
  if (!response.ok) throw Error(response.statusText);
}

interface GetProjectCountArgs {
  codeContains?: string;
  nameContains?: string;
  codeOrNameContains?: string;
  asOfDateFrom?: string;
  asOfDateTo?: string;
  divisionContains?: string;
  projLeaderContains?: string;
  projCoordinatorContains?: string;
  techCoordinatorContains?: string;
  countryCode?: string;
  clientCode?: string;
  clientNameContains?: string;
  workTypeCodes?: string[];
  evalTypeCodes?: string[];
  guidelineCodes?: string[];
  fieldId?: number;
  reservoirId?: number;
  hasFields?: boolean;
  hasReservoirs?: boolean;
}

export async function apiGetProjectCount(
  args: GetProjectCountArgs
): Promise<number> {
  const params: Params = {};
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.asOfDateFrom) {
    params["asOfDateFrom"] = args.asOfDateFrom;
  }
  if (args.asOfDateTo) {
    params["asOfDateTo"] = args.asOfDateTo;
  }
  if (args.divisionContains) {
    params["divisionContains"] = args.divisionContains;
  }
  if (args.projLeaderContains) {
    params["projLeaderContains"] = args.projLeaderContains;
  }
  if (args.projCoordinatorContains) {
    params["projCoordinatorContains"] = args.projCoordinatorContains;
  }
  if (args.techCoordinatorContains) {
    params["techCoordinatorContains"] = args.techCoordinatorContains;
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.clientCode) {
    params["clientCode"] = args.clientCode;
  }
  if (args.clientNameContains) {
    params["clientNameContains"] = args.clientNameContains;
  }
  if (args.workTypeCodes) {
    params["workTypeCodes"] = args.workTypeCodes;
  }
  if (args.evalTypeCodes) {
    params["evalTypeCodes"] = args.evalTypeCodes;
  }
  if (args.guidelineCodes) {
    params["guidelineCodes"] = args.guidelineCodes;
  }
  if (args.fieldId) {
    params["fieldId"] = args.fieldId.toString();
  }
  if (args.reservoirId) {
    params["reservoirId"] = args.reservoirId.toString();
  }
  if (typeof args.hasFields !== "undefined") {
    params["hasFields"] = args.hasFields ? "true" : "false";
  }
  if (typeof args.hasReservoirs !== "undefined") {
    params["hasReservoirs"] = args.hasReservoirs ? "true" : "false";
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "projects/count",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: string = await response.text();
  return parseInt(data, 10);
}

interface GetProjectsArgs {
  codeContains?: string;
  nameContains?: string;
  codeOrNameContains?: string;
  asOfDateFrom?: string;
  asOfDateTo?: string;
  divisionContains?: string;
  projLeaderContains?: string;
  projCoordinatorContains?: string;
  techCoordinatorContains?: string;
  countryCode?: string;
  clientCode?: string;
  clientNameContains?: string;
  workTypeCodes?: string[];
  evalTypeCodes?: string[];
  guidelineCodes?: string[];
  fieldId?: number;
  reservoirId?: number;
  hasFields?: boolean;
  hasReservoirs?: boolean;
  order?: ProjectOrder;
  skip?: number;
  take?: number;
}

export async function apiGetProjects(
  args: GetProjectsArgs
): Promise<Project[]> {
  const params: Params = {};
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.asOfDateFrom) {
    params["asOfDateFrom"] = args.asOfDateFrom;
  }
  if (args.asOfDateTo) {
    params["asOfDateTo"] = args.asOfDateTo;
  }
  if (args.divisionContains) {
    params["divisionContains"] = args.divisionContains;
  }
  if (args.projLeaderContains) {
    params["projLeaderContains"] = args.projLeaderContains;
  }
  if (args.projCoordinatorContains) {
    params["projCoordinatorContains"] = args.projCoordinatorContains;
  }
  if (args.techCoordinatorContains) {
    params["techCoordinatorContains"] = args.techCoordinatorContains;
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.clientCode) {
    params["clientCode"] = args.clientCode;
  }
  if (args.clientNameContains) {
    params["clientNameContains"] = args.clientNameContains;
  }
  if (args.workTypeCodes) {
    params["workTypeCodes"] = args.workTypeCodes;
  }
  if (args.evalTypeCodes) {
    params["evalTypeCodes"] = args.evalTypeCodes;
  }
  if (args.guidelineCodes) {
    params["guidelineCodes"] = args.guidelineCodes;
  }
  if (args.fieldId) {
    params["fieldId"] = args.fieldId.toString();
  }
  if (args.reservoirId) {
    params["reservoirId"] = args.reservoirId.toString();
  }
  if (typeof args.hasFields !== "undefined") {
    params["hasFields"] = args.hasFields ? "true" : "false";
  }
  if (typeof args.hasReservoirs !== "undefined") {
    params["hasReservoirs"] = args.hasReservoirs ? "true" : "false";
  }
  if (args.order) {
    params["order"] = args.order;
  }
  if (args.skip) {
    params["skip"] = args.skip.toString();
  }
  if (args.take) {
    params["take"] = args.take.toString();
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "projects",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Project[] = await response.json();
  return data;
}

interface ExportProjectsArgs {
  codeContains?: string;
  nameContains?: string;
  codeOrNameContains?: string;
  asOfDateFrom?: string;
  asOfDateTo?: string;
  divisionContains?: string;
  projLeaderContains?: string;
  projCoordinatorContains?: string;
  techCoordinatorContains?: string;
  countryCode?: string;
  clientCode?: string;
  clientNameContains?: string;
  workTypeCodes?: string[];
  evalTypeCodes?: string[];
  guidelineCodes?: string[];
  fieldId?: number;
  reservoirId?: number;
  hasFields?: boolean;
  hasReservoirs?: boolean;
  order?: ProjectOrder;
}

export async function apiExportProjectsAsCsv(
  args: ExportProjectsArgs
): Promise<Blob> {
  const params: Params = {};
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.asOfDateFrom) {
    params["asOfDateFrom"] = args.asOfDateFrom;
  }
  if (args.asOfDateTo) {
    params["asOfDateTo"] = args.asOfDateTo;
  }
  if (args.divisionContains) {
    params["divisionContains"] = args.divisionContains;
  }
  if (args.projLeaderContains) {
    params["projLeaderContains"] = args.projLeaderContains;
  }
  if (args.projCoordinatorContains) {
    params["projCoordinatorContains"] = args.projCoordinatorContains;
  }
  if (args.techCoordinatorContains) {
    params["techCoordinatorContains"] = args.techCoordinatorContains;
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.clientCode) {
    params["clientCode"] = args.clientCode;
  }
  if (args.clientNameContains) {
    params["clientNameContains"] = args.clientNameContains;
  }
  if (args.workTypeCodes) {
    params["workTypeCodes"] = args.workTypeCodes;
  }
  if (args.evalTypeCodes) {
    params["evalTypeCodes"] = args.evalTypeCodes;
  }
  if (args.guidelineCodes) {
    params["guidelineCodes"] = args.guidelineCodes;
  }
  if (args.fieldId) {
    params["fieldId"] = args.fieldId.toString();
  }
  if (args.reservoirId) {
    params["reservoirId"] = args.reservoirId.toString();
  }
  if (typeof args.hasFields !== "undefined") {
    params["hasFields"] = args.hasFields ? "true" : "false";
  }
  if (typeof args.hasReservoirs !== "undefined") {
    params["hasReservoirs"] = args.hasReservoirs ? "true" : "false";
  }
  if (args.order) {
    params["order"] = args.order;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "projects/export_csv",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const blob: Blob = await response.blob();
  return blob;
}

export async function apiExportProjectsAsExcel(
  args: ExportProjectsArgs
): Promise<Blob> {
  const params: Params = {};
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.asOfDateFrom) {
    params["asOfDateFrom"] = args.asOfDateFrom;
  }
  if (args.asOfDateTo) {
    params["asOfDateTo"] = args.asOfDateTo;
  }
  if (args.divisionContains) {
    params["divisionContains"] = args.divisionContains;
  }
  if (args.projLeaderContains) {
    params["projLeaderContains"] = args.projLeaderContains;
  }
  if (args.projCoordinatorContains) {
    params["projCoordinatorContains"] = args.projCoordinatorContains;
  }
  if (args.techCoordinatorContains) {
    params["techCoordinatorContains"] = args.techCoordinatorContains;
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.clientCode) {
    params["clientCode"] = args.clientCode;
  }
  if (args.clientNameContains) {
    params["clientNameContains"] = args.clientNameContains;
  }
  if (args.workTypeCodes) {
    params["workTypeCodes"] = args.workTypeCodes;
  }
  if (args.evalTypeCodes) {
    params["evalTypeCodes"] = args.evalTypeCodes;
  }
  if (args.guidelineCodes) {
    params["guidelineCodes"] = args.guidelineCodes;
  }
  if (args.fieldId) {
    params["fieldId"] = args.fieldId.toString();
  }
  if (args.reservoirId) {
    params["reservoirId"] = args.reservoirId.toString();
  }
  if (typeof args.hasFields !== "undefined") {
    params["hasFields"] = args.hasFields ? "true" : "false";
  }
  if (typeof args.hasReservoirs !== "undefined") {
    params["hasReservoirs"] = args.hasReservoirs ? "true" : "false";
  }
  if (args.order) {
    params["order"] = args.order;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "projects/export_excel",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const blob: Blob = await response.blob();
  return blob;
}

interface GetFieldCountArgs {
  codeContains?: string;
  nameContains?: string;
  aliasContains?: string;
  barNameContains?: string;
  locationContains?: string;
  addedByContains?: string;
  updatedByContains?: string;
  addedOnFrom?: Date;
  addedOnTo?: Date;
  updatedOnFrom?: Date;
  updatedOnTo?: Date;
  codeOrNameStartsWith?: string;
  codeOrNameContains?: string;
  codeOrNameEndsWith?: string;
  barCode?: number;
  countryCode?: string;
  projectNo?: string;
  hasProjects?: boolean;
  includeDeleted?: boolean;
}

export async function apiGetFieldCount(
  args: GetFieldCountArgs
): Promise<number> {
  const params: Params = {};
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.aliasContains) {
    params["aliasContains"] = args.aliasContains;
  }
  if (args.barNameContains) {
    params["barNameContains"] = args.barNameContains;
  }
  if (args.locationContains) {
    params["locationContains"] = args.locationContains;
  }
  if (args.addedByContains) {
    params["addedByContains"] = args.addedByContains;
  }
  if (args.updatedByContains) {
    params["updatedByContains"] = args.updatedByContains;
  }
  if (args.addedOnFrom) {
    params["addedOnFrom"] = args.addedOnFrom.toISOString();
  }
  if (args.addedOnTo) {
    params["addedOnTo"] = args.addedOnTo.toISOString();
  }
  if (args.updatedOnFrom) {
    params["updatedOnFrom"] = args.updatedOnFrom.toISOString();
  }
  if (args.updatedOnTo) {
    params["updatedOnTo"] = args.updatedOnTo.toISOString();
  }
  if (args.codeOrNameStartsWith) {
    params["codeOrNameStartsWith"] = args.codeOrNameStartsWith;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.codeOrNameEndsWith) {
    params["codeOrNameEndsWith"] = args.codeOrNameEndsWith;
  }
  if (args.barCode) {
    params["barCode"] = args.barCode.toString();
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.projectNo) {
    params["projectNo"] = args.projectNo;
  }
  if (typeof args.hasProjects !== "undefined") {
    params["hasProjects"] = args.hasProjects ? "true" : "false";
  }
  if (args.includeDeleted) {
    params["includeDeleted"] = args.includeDeleted ? "true" : "false";
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "fields/count",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: string = await response.text();
  return parseInt(data, 10);
}

interface GetFieldsArgs {
  ids?: number[];
  codeContains?: string;
  nameContains?: string;
  aliasContains?: string;
  barNameContains?: string;
  locationContains?: string;
  addedByContains?: string;
  updatedByContains?: string;
  addedOnFrom?: Date;
  addedOnTo?: Date;
  updatedOnFrom?: Date;
  updatedOnTo?: Date;
  codeOrNameStartsWith?: string;
  codeOrNameContains?: string;
  codeOrNameEndsWith?: string;
  barCode?: number;
  countryCode?: string;
  projectNo?: string;
  hasProjects?: boolean;
  includeDeleted?: boolean;
  order?: FieldOrder;
  skip?: number;
  take?: number;
}

export async function apiGetFields(args: GetFieldsArgs): Promise<Field[]> {
  const params: Params = {};
  if (args.ids) {
    params["ids"] = args.ids.map((x) => x.toString());
  }
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.aliasContains) {
    params["aliasContains"] = args.aliasContains;
  }
  if (args.barNameContains) {
    params["barNameContains"] = args.barNameContains;
  }
  if (args.locationContains) {
    params["locationContains"] = args.locationContains;
  }
  if (args.addedByContains) {
    params["addedByContains"] = args.addedByContains;
  }
  if (args.updatedByContains) {
    params["updatedByContains"] = args.updatedByContains;
  }
  if (args.addedOnFrom) {
    params["addedOnFrom"] = args.addedOnFrom.toISOString();
  }
  if (args.addedOnTo) {
    params["addedOnTo"] = args.addedOnTo.toISOString();
  }
  if (args.updatedOnFrom) {
    params["updatedOnFrom"] = args.updatedOnFrom.toISOString();
  }
  if (args.updatedOnTo) {
    params["updatedOnTo"] = args.updatedOnTo.toISOString();
  }
  if (args.codeOrNameStartsWith) {
    params["codeOrNameStartsWith"] = args.codeOrNameStartsWith;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.codeOrNameEndsWith) {
    params["codeOrNameEndsWith"] = args.codeOrNameEndsWith;
  }
  if (args.barCode) {
    params["barCode"] = args.barCode.toString();
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.projectNo) {
    params["projectNo"] = args.projectNo;
  }
  if (typeof args.hasProjects !== "undefined") {
    params["hasProjects"] = args.hasProjects ? "true" : "false";
  }
  if (args.includeDeleted) {
    params["includeDeleted"] = args.includeDeleted ? "true" : "false";
  }
  if (args.order) {
    params["order"] = args.order;
  }
  if (args.skip) {
    params["skip"] = args.skip.toString();
  }
  if (args.take) {
    params["take"] = args.take.toString();
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "fields",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Field[] = await response.json();
  return data;
}

interface ExportFieldsArgs {
  codeContains?: string;
  nameContains?: string;
  aliasContains?: string;
  barNameContains?: string;
  locationContains?: string;
  addedByContains?: string;
  updatedByContains?: string;
  addedOnFrom?: Date;
  addedOnTo?: Date;
  updatedOnFrom?: Date;
  updatedOnTo?: Date;
  codeOrNameStartsWith?: string;
  codeOrNameContains?: string;
  codeOrNameEndsWith?: string;
  barCode?: number;
  countryCode?: string;
  projectNo?: string;
  hasProjects?: boolean;
  includeDeleted?: boolean;
  order?: FieldOrder;
}

export async function apiExportFieldsAsCsv(
  args: ExportFieldsArgs
): Promise<Blob> {
  const params: Params = {};
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.aliasContains) {
    params["aliasContains"] = args.aliasContains;
  }
  if (args.barNameContains) {
    params["barNameContains"] = args.barNameContains;
  }
  if (args.locationContains) {
    params["locationContains"] = args.locationContains;
  }
  if (args.addedByContains) {
    params["addedByContains"] = args.addedByContains;
  }
  if (args.updatedByContains) {
    params["updatedByContains"] = args.updatedByContains;
  }
  if (args.addedOnFrom) {
    params["addedOnFrom"] = args.addedOnFrom.toISOString();
  }
  if (args.addedOnTo) {
    params["addedOnTo"] = args.addedOnTo.toISOString();
  }
  if (args.updatedOnFrom) {
    params["updatedOnFrom"] = args.updatedOnFrom.toISOString();
  }
  if (args.updatedOnTo) {
    params["updatedOnTo"] = args.updatedOnTo.toISOString();
  }
  if (args.codeOrNameStartsWith) {
    params["codeOrNameStartsWith"] = args.codeOrNameStartsWith;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.codeOrNameEndsWith) {
    params["codeOrNameEndsWith"] = args.codeOrNameEndsWith;
  }
  if (args.barCode) {
    params["barCode"] = args.barCode.toString();
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.projectNo) {
    params["projectNo"] = args.projectNo;
  }
  if (typeof args.hasProjects !== "undefined") {
    params["hasProjects"] = args.hasProjects ? "true" : "false";
  }
  if (args.includeDeleted) {
    params["includeDeleted"] = args.includeDeleted ? "true" : "false";
  }
  if (args.order) {
    params["order"] = args.order;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "fields/export_csv",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const blob: Blob = await response.blob();
  return blob;
}

export async function apiExportFieldsAsExcel(
  args: ExportFieldsArgs
): Promise<Blob> {
  const params: Params = {};
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.aliasContains) {
    params["aliasContains"] = args.aliasContains;
  }
  if (args.barNameContains) {
    params["barNameContains"] = args.barNameContains;
  }
  if (args.locationContains) {
    params["locationContains"] = args.locationContains;
  }
  if (args.addedByContains) {
    params["addedByContains"] = args.addedByContains;
  }
  if (args.updatedByContains) {
    params["updatedByContains"] = args.updatedByContains;
  }
  if (args.addedOnFrom) {
    params["addedOnFrom"] = args.addedOnFrom.toISOString();
  }
  if (args.addedOnTo) {
    params["addedOnTo"] = args.addedOnTo.toISOString();
  }
  if (args.updatedOnFrom) {
    params["updatedOnFrom"] = args.updatedOnFrom.toISOString();
  }
  if (args.updatedOnTo) {
    params["updatedOnTo"] = args.updatedOnTo.toISOString();
  }
  if (args.codeOrNameStartsWith) {
    params["codeOrNameStartsWith"] = args.codeOrNameStartsWith;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.codeOrNameEndsWith) {
    params["codeOrNameEndsWith"] = args.codeOrNameEndsWith;
  }
  if (args.barCode) {
    params["barCode"] = args.barCode.toString();
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.projectNo) {
    params["projectNo"] = args.projectNo;
  }
  if (typeof args.hasProjects !== "undefined") {
    params["hasProjects"] = args.hasProjects ? "true" : "false";
  }
  if (args.includeDeleted) {
    params["includeDeleted"] = args.includeDeleted ? "true" : "false";
  }
  if (args.order) {
    params["order"] = args.order;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "fields/export_excel",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const blob: Blob = await response.blob();
  return blob;
}

interface GetReservoirCountArgs {
  codeContains?: string;
  nameContains?: string;
  aliasContains?: string;
  barNameContains?: string;
  eraContains?: string;
  lithologyContains?: string;
  periodContains?: string;
  productContains?: string;
  addedByContains?: string;
  updatedByContains?: string;
  addedOnFrom?: Date;
  addedOnTo?: Date;
  updatedOnFrom?: Date;
  updatedOnTo?: Date;
  codeOrNameStartsWith?: string;
  codeOrNameContains?: string;
  codeOrNameEndsWith?: string;
  barCode?: number;
  countryCode?: string;
  projectNo?: string;
  hasProjects?: boolean;
  includeDeleted?: boolean;
}

export async function apiGetReservoirCount(
  args: GetReservoirCountArgs
): Promise<number> {
  const params: Params = {};
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.aliasContains) {
    params["aliasContains"] = args.aliasContains;
  }
  if (args.barNameContains) {
    params["barNameContains"] = args.barNameContains;
  }
  if (args.eraContains) {
    params["eraContains"] = args.eraContains;
  }
  if (args.lithologyContains) {
    params["lithologyContains"] = args.lithologyContains;
  }
  if (args.periodContains) {
    params["periodContains"] = args.periodContains;
  }
  if (args.productContains) {
    params["productContains"] = args.productContains;
  }
  if (args.addedByContains) {
    params["addedByContains"] = args.addedByContains;
  }
  if (args.updatedByContains) {
    params["updatedByContains"] = args.updatedByContains;
  }
  if (args.addedOnFrom) {
    params["addedOnFrom"] = args.addedOnFrom.toISOString();
  }
  if (args.addedOnTo) {
    params["addedOnTo"] = args.addedOnTo.toISOString();
  }
  if (args.updatedOnFrom) {
    params["updatedOnFrom"] = args.updatedOnFrom.toISOString();
  }
  if (args.updatedOnTo) {
    params["updatedOnTo"] = args.updatedOnTo.toISOString();
  }
  if (args.codeOrNameStartsWith) {
    params["codeOrNameStartsWith"] = args.codeOrNameStartsWith;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.codeOrNameEndsWith) {
    params["codeOrNameEndsWith"] = args.codeOrNameEndsWith;
  }
  if (args.barCode) {
    params["barCode"] = args.barCode.toString();
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.projectNo) {
    params["projectNo"] = args.projectNo;
  }
  if (typeof args.hasProjects !== "undefined") {
    params["hasProjects"] = args.hasProjects ? "true" : "false";
  }
  if (args.includeDeleted) {
    params["includeDeleted"] = args.includeDeleted ? "true" : "false";
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "reservoirs/count",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: string = await response.text();
  return parseInt(data, 10);
}

interface GetReservoirsArgs {
  ids?: number[];
  codeContains?: string;
  nameContains?: string;
  aliasContains?: string;
  barNameContains?: string;
  eraContains?: string;
  lithologyContains?: string;
  periodContains?: string;
  productContains?: string;
  addedByContains?: string;
  updatedByContains?: string;
  addedOnFrom?: Date;
  addedOnTo?: Date;
  updatedOnFrom?: Date;
  updatedOnTo?: Date;
  codeOrNameStartsWith?: string;
  codeOrNameContains?: string;
  codeOrNameEndsWith?: string;
  barCode?: number;
  countryCode?: string;
  projectNo?: string;
  hasProjects?: boolean;
  includeDeleted?: boolean;
  order?: ReservoirOrder;
  skip?: number;
  take?: number;
}

export async function apiGetReservoirs(
  args: GetReservoirsArgs
): Promise<Reservoir[]> {
  const params: Params = {};
  if (args.ids) {
    params["ids"] = args.ids.map((x) => x.toString());
  }
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.aliasContains) {
    params["aliasContains"] = args.aliasContains;
  }
  if (args.barNameContains) {
    params["barNameContains"] = args.barNameContains;
  }
  if (args.eraContains) {
    params["eraContains"] = args.eraContains;
  }
  if (args.lithologyContains) {
    params["lithologyContains"] = args.lithologyContains;
  }
  if (args.periodContains) {
    params["periodContains"] = args.periodContains;
  }
  if (args.productContains) {
    params["productContains"] = args.productContains;
  }
  if (args.addedByContains) {
    params["addedByContains"] = args.addedByContains;
  }
  if (args.updatedByContains) {
    params["updatedByContains"] = args.updatedByContains;
  }
  if (args.addedOnFrom) {
    params["addedOnFrom"] = args.addedOnFrom.toISOString();
  }
  if (args.addedOnTo) {
    params["addedOnTo"] = args.addedOnTo.toISOString();
  }
  if (args.updatedOnFrom) {
    params["updatedOnFrom"] = args.updatedOnFrom.toISOString();
  }
  if (args.updatedOnTo) {
    params["updatedOnTo"] = args.updatedOnTo.toISOString();
  }
  if (args.codeOrNameStartsWith) {
    params["codeOrNameStartsWith"] = args.codeOrNameStartsWith;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.codeOrNameEndsWith) {
    params["codeOrNameEndsWith"] = args.codeOrNameEndsWith;
  }
  if (args.barCode) {
    params["barCode"] = args.barCode.toString();
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.projectNo) {
    params["projectNo"] = args.projectNo;
  }
  if (typeof args.hasProjects !== "undefined") {
    params["hasProjects"] = args.hasProjects ? "true" : "false";
  }
  if (args.includeDeleted) {
    params["includeDeleted"] = args.includeDeleted ? "true" : "false";
  }
  if (args.order) {
    params["order"] = args.order;
  }
  if (args.skip) {
    params["skip"] = args.skip.toString();
  }
  if (args.take) {
    params["take"] = args.take.toString();
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "reservoirs",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Reservoir[] = await response.json();
  return data;
}

interface ExportReservoirsArgs {
  codeContains?: string;
  nameContains?: string;
  aliasContains?: string;
  barNameContains?: string;
  eraContains?: string;
  lithologyContains?: string;
  periodContains?: string;
  productContains?: string;
  addedByContains?: string;
  updatedByContains?: string;
  addedOnFrom?: Date;
  addedOnTo?: Date;
  updatedOnFrom?: Date;
  updatedOnTo?: Date;
  codeOrNameStartsWith?: string;
  codeOrNameContains?: string;
  codeOrNameEndsWith?: string;
  barCode?: number;
  countryCode?: string;
  projectNo?: string;
  hasProjects?: boolean;
  includeDeleted?: boolean;
  order?: ReservoirOrder;
}

export async function apiExportReservoirsAsCsv(
  args: ExportReservoirsArgs
): Promise<Blob> {
  const params: Params = {};
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.aliasContains) {
    params["aliasContains"] = args.aliasContains;
  }
  if (args.barNameContains) {
    params["barNameContains"] = args.barNameContains;
  }
  if (args.eraContains) {
    params["eraContains"] = args.eraContains;
  }
  if (args.lithologyContains) {
    params["lithologyContains"] = args.lithologyContains;
  }
  if (args.periodContains) {
    params["periodContains"] = args.periodContains;
  }
  if (args.productContains) {
    params["productContains"] = args.productContains;
  }
  if (args.addedByContains) {
    params["addedByContains"] = args.addedByContains;
  }
  if (args.updatedByContains) {
    params["updatedByContains"] = args.updatedByContains;
  }
  if (args.addedOnFrom) {
    params["addedOnFrom"] = args.addedOnFrom.toISOString();
  }
  if (args.addedOnTo) {
    params["addedOnTo"] = args.addedOnTo.toISOString();
  }
  if (args.updatedOnFrom) {
    params["updatedOnFrom"] = args.updatedOnFrom.toISOString();
  }
  if (args.updatedOnTo) {
    params["updatedOnTo"] = args.updatedOnTo.toISOString();
  }
  if (args.codeOrNameStartsWith) {
    params["codeOrNameStartsWith"] = args.codeOrNameStartsWith;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.codeOrNameEndsWith) {
    params["codeOrNameEndsWith"] = args.codeOrNameEndsWith;
  }
  if (args.barCode) {
    params["barCode"] = args.barCode.toString();
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.projectNo) {
    params["projectNo"] = args.projectNo;
  }
  if (typeof args.hasProjects !== "undefined") {
    params["hasProjects"] = args.hasProjects ? "true" : "false";
  }
  if (args.includeDeleted) {
    params["includeDeleted"] = args.includeDeleted ? "true" : "false";
  }
  if (args.order) {
    params["order"] = args.order;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "reservoirs/export_csv",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const blob: Blob = await response.blob();
  return blob;
}

export async function apiExportReservoirsAsExcel(
  args: ExportReservoirsArgs
): Promise<Blob> {
  const params: Params = {};
  if (args.codeContains) {
    params["codeContains"] = args.codeContains;
  }
  if (args.nameContains) {
    params["nameContains"] = args.nameContains;
  }
  if (args.aliasContains) {
    params["aliasContains"] = args.aliasContains;
  }
  if (args.barNameContains) {
    params["barNameContains"] = args.barNameContains;
  }
  if (args.eraContains) {
    params["eraContains"] = args.eraContains;
  }
  if (args.lithologyContains) {
    params["lithologyContains"] = args.lithologyContains;
  }
  if (args.periodContains) {
    params["periodContains"] = args.periodContains;
  }
  if (args.productContains) {
    params["productContains"] = args.productContains;
  }
  if (args.addedByContains) {
    params["addedByContains"] = args.addedByContains;
  }
  if (args.updatedByContains) {
    params["updatedByContains"] = args.updatedByContains;
  }
  if (args.addedOnFrom) {
    params["addedOnFrom"] = args.addedOnFrom.toISOString();
  }
  if (args.addedOnTo) {
    params["addedOnTo"] = args.addedOnTo.toISOString();
  }
  if (args.updatedOnFrom) {
    params["updatedOnFrom"] = args.updatedOnFrom.toISOString();
  }
  if (args.updatedOnTo) {
    params["updatedOnTo"] = args.updatedOnTo.toISOString();
  }
  if (args.codeOrNameStartsWith) {
    params["codeOrNameStartsWith"] = args.codeOrNameStartsWith;
  }
  if (args.codeOrNameContains) {
    params["codeOrNameContains"] = args.codeOrNameContains;
  }
  if (args.codeOrNameEndsWith) {
    params["codeOrNameEndsWith"] = args.codeOrNameEndsWith;
  }
  if (args.barCode) {
    params["barCode"] = args.barCode.toString();
  }
  if (args.countryCode) {
    params["countryCode"] = args.countryCode;
  }
  if (args.projectNo) {
    params["projectNo"] = args.projectNo;
  }
  if (typeof args.hasProjects !== "undefined") {
    params["hasProjects"] = args.hasProjects ? "true" : "false";
  }
  if (args.includeDeleted) {
    params["includeDeleted"] = args.includeDeleted ? "true" : "false";
  }
  if (args.order) {
    params["order"] = args.order;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "reservoirs/export_excel",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const blob: Blob = await response.blob();
  return blob;
}

export interface GetProjectInfosResponse {
  projects: ProjectInfo[];
}

export async function apiGetProjectInfos(): Promise<GetProjectInfosResponse> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "projectinfo",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: GetProjectInfosResponse = await response.json();
  return data;
}

export async function apiGetProjectPropertyCount(
  projectId: string,
  fields: boolean
): Promise<number> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "export/checkpropertycount",
    params: { projectId: projectId, fields: fields ? "true" : "false" },
  });
  if (!response.ok) throw Error(response.statusText);
  const data: string = await response.text();
  return parseInt(data, 10);
}

export function apiExportProjectFields(projectId: string) {
  window.open(formUrl("export/fields", { projectId: projectId }), "_blank");
}

export function apiExportProjectReservoirs(projectId: string) {
  window.open(formUrl("export/reservoirs", { projectId: projectId }), "_blank");
}

export interface UploadPropertyResult {
  fieldCode: string;
  fieldName: string;
  reservoirCode: string;
  reservoirName: string;
  importSuccess: boolean;
  importFailureMessage: string;
}

export interface UploadFileResponse {
  isValid: boolean;
  errorMessage: string;
  propertiesType: string;
  csvResults: string;
  uploadResults: UploadPropertyResult[];
}

export async function apiUploadFile(
  projectId: string,
  type: number,
  file: File
): Promise<UploadFileResponse> {
  const token: string = await getAccessToken();

  const url: string = formUrl("uploadfile", {
    type: type.toString(),
    projectId: projectId,
  });

  const body: FormData = new FormData();
  body.append("file", file);

  const response: Response = await fetch(url, {
    method: "POST",
    body: body,
    headers: { Authorization: "Bearer " + token },
  });

  if (!response.ok) throw Error(response.statusText);
  const data: UploadFileResponse = await response.json();
  return data;
}

export interface GetDocumentsForProjectResponse {
  selectedDocuments: string[];
  allDocuments: string[];
}

export async function apiGetDocumentsForProject(
  projectId: string
): Promise<GetDocumentsForProjectResponse> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "documents/get",
    params: { projectId: projectId },
  });
  if (!response.ok) throw Error(response.statusText);
  const data: GetDocumentsForProjectResponse = await response.json();
  return data;
}

export interface ProjectDocument {
  id: number;
  filename: string;
  href: string;
}

export async function apiGetProjectDocuments(
  project: string
): Promise<ProjectDocument[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "documents/projectdocuments",
    params: { projectname: project },
  });
  if (!response.ok) throw Error(response.statusText);
  const data: ProjectDocument[] = await response.json();
  return data;
}

export interface SetProjectDocumentsBody {
  projectId: string;
  documentNames: string[];
}

export async function apiSetProjectDocuments(
  data: SetProjectDocumentsBody
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "PUT",
    path: "documents/projectdocuments",
    body: data,
  });
  if (!response.ok) throw Error(response.statusText);
}

export async function apiDeleteProjectDocument(
  documentId: number
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "DELETE",
    path: "documents/deletedocument",
    params: { documentId: documentId.toString() },
  });
  if (!response.ok) throw Error(response.statusText);
}

export async function apiGetProjectReports(
  projectNo: string
): Promise<Report[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "search/reports",
    params: { projNo: projectNo },
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Report[] = await response.json();
  return data;
}

export async function apiGetUsers(): Promise<User[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "users/getusers",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: User[] = await response.json();
  return data;
}

export async function apiAddUser(user: User): Promise<UserActionResult> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "users/adduser",
    body: user,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: UserActionResult = await response.json();
  return data;
}

export async function apiEditUser(user: User): Promise<UserActionResult> {
  const response: Response = await apiCallInner({
    method: "PUT",
    path: "users/edituser",
    body: user,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: UserActionResult = await response.json();
  return data;
}

export async function apiDeleteUser(user: User): Promise<UserActionResult> {
  const response: Response = await apiCallInner({
    method: "DELETE",
    path: "users/deleteuser",
    body: user,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: UserActionResult = await response.json();
  return data;
}
