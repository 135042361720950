import {
  Box,
  Button,
  Checkbox,
  Link,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import { Fragment, ReactElement, useState } from "react";
import { Basin, EmptyField, Field } from "../../../types";
import FieldInfoDialog from "../../FieldInfoDialog";
import TablePaginationActions from "../../TablePaginationActions";

interface FieldNodesProps {
  basin: Basin;
  basinFields: { [barCode: number]: Field[] | undefined };
  checkedFieldIds: Set<number>;
  onFieldCheckedChange: (fieldId: number, checked: boolean) => void;
  loadingNodes: string[];
  editingMode: boolean;
}

export default function FieldNodes(props: FieldNodesProps): ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogField, setDialogField] = useState(EmptyField);

  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage] = useState(15);

  const getBasinNodeIdForField = (basin: Basin) => {
    return basin === undefined || basin.code === undefined
      ? Math.random().toString()
      : "FLDBAS_" + basin.coCode + "_" + basin.code.toString();
  };

  const getFieldNodeId = (field: Field) => {
    return field === undefined || field.code === undefined
      ? Math.random().toString()
      : "FLD_" + field.code.toString();
  };

  const selectFields = (fields: Field[]) => {
    for (const field of fields) {
      if (!props.checkedFieldIds.has(field.id)) {
        props.onFieldCheckedChange(field.id, true);
      }
    }
  };

  const invertSelectionForFields = (fields: Field[]) => {
    for (const field of fields) {
      if (props.checkedFieldIds.has(field.id)) {
        props.onFieldCheckedChange(field.id, false);
      } else {
        props.onFieldCheckedChange(field.id, true);
      }
    }
  };

  const basinFields: Field[] = props.basinFields[props.basin.code] || [];

  const nodeId: string = getBasinNodeIdForField(props.basin);
  const isLoading: boolean = props.loadingNodes.some((x) => x === nodeId);

  if (!props.basin.fieldCount) return <></>;

  return (
    <TreeItem
      nodeId={nodeId}
      key={nodeId}
      label={
        <Fragment>
          <Typography variant="body2" component="span">
            Fields
          </Typography>
          &nbsp;
          <Typography variant="body2" component="span" color="textSecondary">
            {props.basin.fieldCount}
          </Typography>
        </Fragment>
      }
    >
      {isLoading && (
        <Typography variant="body2" color="textSecondary">
          Loading ...
        </Typography>
      )}

      {basinFields.length > 0 && (
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Alias</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {basinFields
              .slice(
                tablePage * rowsPerPage,
                tablePage * rowsPerPage + rowsPerPage
              )
              .map((field) => (
                <TableRow key={getFieldNodeId(field)}>
                  <TableCell>
                    <Checkbox
                      id={"fldcb_" + field.id}
                      style={{
                        padding: "0",
                        width: "16px",
                        height: "16px",
                      }}
                      color="primary"
                      disabled={props.editingMode === false}
                      checked={props.checkedFieldIds.has(field.id)}
                      onChange={(_, checked) =>
                        props.onFieldCheckedChange(field.id, checked)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" component="span">
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setDialogOpen(true);
                          setDialogField(field);
                        }}
                      >
                        {field.code === undefined ? "_" : field.code}
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" component="span">
                      {field.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" component="span">
                      {field.alias}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" component="span">
                      {field.location}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" component="span">
                      {field.comment}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <Box display="flex" alignItems="center">
                  {props.editingMode && (
                    <>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => selectFields(basinFields)}
                      >
                        Select All
                      </Button>
                      <span style={{ padding: "0.2rem" }}>&nbsp;</span>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => invertSelectionForFields(basinFields)}
                      >
                        Invert Selection
                      </Button>
                    </>
                  )}
                  <TablePagination
                    style={{ flexGrow: 1 }}
                    component="div"
                    rowsPerPageOptions={[]}
                    count={basinFields.length}
                    rowsPerPage={rowsPerPage}
                    page={tablePage}
                    onPageChange={(_, p) => setTablePage(p)}
                    ActionsComponent={TablePaginationActions}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}

      <FieldInfoDialog
        open={dialogOpen}
        field={dialogField}
        onClose={() => setDialogOpen(false)}
      />
    </TreeItem>
  );
}
