import {
  Box,
  Button,
  Checkbox,
  Link,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import { Fragment, ReactElement, useState } from "react";
import { Basin, EmptyReservoir, Reservoir } from "../../../types";
import ReservoirInfoDialog from "../../ReservoirInfoDialog";
import TablePaginationActions from "../../TablePaginationActions";

interface ReservoirNodesProps {
  basin: Basin;
  basinReservoirs: { [barCode: number]: Reservoir[] | undefined };
  checkedReservoirIds: Set<number>;
  onReservoirCheckedChanged: (reservoirId: number, checked: boolean) => void;
  loadingNodes: string[];
  editingMode: boolean;
}

export default function ReservoirNodes(
  props: ReservoirNodesProps
): ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogReservoir, setDialogReservoir] = useState(EmptyReservoir);

  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage] = useState(15);

  const getBasinNodeIdForReservoir = (basin: Basin) => {
    return basin === undefined || basin.code === undefined
      ? Math.random().toString()
      : "RESBAS_" + basin.coCode + "_" + basin.code.toString();
  };

  const getReservoirNodeId = (reservoir: Reservoir) => {
    return reservoir === undefined || reservoir.code === undefined
      ? Math.random().toString()
      : "RES_" + reservoir.code.toString();
  };

  const selectReservoirs = (reservoirs: Reservoir[]) => {
    for (const reservoir of reservoirs) {
      if (!props.checkedReservoirIds.has(reservoir.id)) {
        props.onReservoirCheckedChanged(reservoir.id, true);
      }
    }
  };

  const invertSelectionForReservoirs = (reservoirs: Reservoir[]) => {
    for (const reservoir of reservoirs) {
      if (props.checkedReservoirIds.has(reservoir.id)) {
        props.onReservoirCheckedChanged(reservoir.id, false);
      } else {
        props.onReservoirCheckedChanged(reservoir.id, true);
      }
    }
  };

  const basinReservoirs: Reservoir[] =
    props.basinReservoirs[props.basin.code] || [];

  const nodeId: string = getBasinNodeIdForReservoir(props.basin);
  const isLoading: boolean = props.loadingNodes.some((x) => x === nodeId);

  if (!props.basin.reservoirCount) return <></>;

  return (
    <TreeItem
      label={
        <Fragment>
          <Typography variant="body2" component="span">
            Reservoirs
          </Typography>
          &nbsp;
          <Typography variant="body2" component="span" color="textSecondary">
            {props.basin.reservoirCount}
          </Typography>
        </Fragment>
      }
      nodeId={nodeId}
      key={nodeId}
    >
      {isLoading && (
        <Typography variant="body2" color="textSecondary">
          Loading ...
        </Typography>
      )}

      {basinReservoirs.length > 0 && (
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Alias</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {basinReservoirs
              .slice(
                tablePage * rowsPerPage,
                tablePage * rowsPerPage + rowsPerPage
              )
              .map((reservoir) => (
                <TableRow key={getReservoirNodeId(reservoir)}>
                  <TableCell>
                    <Checkbox
                      id={"fldcb_" + reservoir.id}
                      style={{
                        padding: "0",
                        width: "16px",
                        height: "16px",
                      }}
                      color="primary"
                      disabled={props.editingMode === false}
                      checked={props.checkedReservoirIds.has(reservoir.id)}
                      onChange={(_, checked) =>
                        props.onReservoirCheckedChanged(reservoir.id, checked)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" component="span">
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setDialogOpen(true);
                          setDialogReservoir(reservoir);
                        }}
                      >
                        {reservoir.code === undefined ? "_" : reservoir.code}
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" component="span">
                      {reservoir.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" component="span">
                      {reservoir.alias}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" component="span">
                      {reservoir.product}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" component="span">
                      {reservoir.comment}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <Box display="flex" alignItems="center">
                  {props.editingMode && (
                    <>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => selectReservoirs(basinReservoirs)}
                      >
                        Select All
                      </Button>
                      <span style={{ padding: "0.2rem" }}>&nbsp;</span>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() =>
                          invertSelectionForReservoirs(basinReservoirs)
                        }
                      >
                        Invert Selection
                      </Button>
                    </>
                  )}
                  <TablePagination
                    style={{ flexGrow: 1 }}
                    component="div"
                    rowsPerPageOptions={[]}
                    count={basinReservoirs.length}
                    rowsPerPage={rowsPerPage}
                    page={tablePage}
                    onPageChange={(_, p) => setTablePage(p)}
                    ActionsComponent={TablePaginationActions}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}

      <ReservoirInfoDialog
        open={dialogOpen}
        reservoir={dialogReservoir}
        onClose={() => setDialogOpen(false)}
      />
    </TreeItem>
  );
}
