import DateFnsUtils from "@date-io/date-fns";
import MaterialTable from "@material-table/core";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import "date-fns";
import { ChangeEvent, Component, CSSProperties, Fragment, KeyboardEvent, ReactNode } from "react";
import {
    apiExportFieldsAsCsv,
    apiExportFieldsAsExcel,
    apiExportProjectsAsCsv,
    apiExportProjectsAsExcel,
    apiExportReservoirsAsCsv,
    apiExportReservoirsAsExcel,
    apiGetClients,
    apiGetClientsForCountry,
    apiGetCountries,
    apiGetFields,
    apiGetProjectReports,
    apiGetProjects,
    apiGetReservoirs,
} from "../api";
import { Client, Country, EmptyField, EmptyProject, EmptyReservoir, Field, Project, Report, Reservoir } from "../types";
import { saveBlob } from "../utils";
import FieldInfoDialog from "./FieldInfoDialog";
import LoadingOverlay from "./LoadingOverlay";
import ProjectInfoDialog from "./ProjectInfoDialog";
import ReservoirInfoDialog from "./ReservoirInfoDialog";
import TablePaginationActions from "./TablePaginationActions";

interface LegacySearchState {
    fieldsReservoirsTabValue: number;
    projectsTabValue: number;
    reportsTabValue: number;
    countryClientSelectedOption: string;

    projectDetailsOpen: boolean;
    fieldDetailsOpen: boolean;
    reservoirsDetailsOpen: boolean;

    filterCountryVisible: boolean;
    filterCountry: string;
    filterClient: string;
    filterProjectName: string;
    filterFieldName: string;
    filterFieldTextPos: "S" | "C" | "E";
    filterReservoirName: string;
    filterReservoirTextPos: "S" | "C" | "E";
    filterAodStart: Date | null;
    filterAodEnd: Date | null;

    countryFilterDisabled: boolean;
    projectFilterDisabled: boolean;
    fieldFilterDisabled: boolean;
    reservoirFilterDisabled: boolean;
    projectAsOfDateFilterDisabled: boolean;

    loadingActive: boolean;

    // autocomplete values
    countries: Country[];
    clients: Client[];
    countryClients: Client[];
    clientCountries: Country[];

    // autocomplete clearing value (forcing rerender)
    countryClientsKey: string;
    clientCountriesKey: string;

    projectsFilter: {} | null;
    fieldsFilter: {} | null;
    reservoirsFilter: {} | null;

    projects: Project[];
    fields: Field[];
    reservoirs: Reservoir[];

    projectReports: Report[];

    // pagination
    rowsPerPage: number;
    projectsTablePage: number;
    projectReportsTablePage: number;
    fieldsTablePage: number;
    reservoirsTablePage: number;

    // details modal
    selectedProject: Project;
    selectedField: Field;
    selectedReservoir: Reservoir;
}

class LegacySearch extends Component<{}, LegacySearchState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            fieldsReservoirsTabValue: 0,
            projectsTabValue: 0,
            reportsTabValue: 0,
            countryClientSelectedOption: "country",

            projectDetailsOpen: false,
            fieldDetailsOpen: false,
            reservoirsDetailsOpen: false,

            filterCountryVisible: true,
            filterCountry: "-",
            filterClient: "-",
            filterProjectName: "",
            filterFieldName: "",
            filterFieldTextPos: "C",
            filterReservoirName: "",
            filterReservoirTextPos: "C",
            filterAodStart: null,
            filterAodEnd: null,

            countryFilterDisabled: false,
            projectFilterDisabled: false,
            fieldFilterDisabled: false,
            reservoirFilterDisabled: false,
            projectAsOfDateFilterDisabled: false,

            loadingActive: false,

            // autocomplete values
            countries: [],
            clients: [],
            countryClients: [],
            clientCountries: [],

            // autocomplete clearing value (forcing rerender)
            countryClientsKey: "countryClientsAutocomplete",
            clientCountriesKey: "clientCountriesAutocomplete",

            projectsFilter: null,
            fieldsFilter: null,
            reservoirsFilter: null,

            projects: [],
            fields: [],
            reservoirs: [],

            projectReports: [],

            // pagination
            rowsPerPage: 10,
            projectsTablePage: 0,
            projectReportsTablePage: 0,
            fieldsTablePage: 0,
            reservoirsTablePage: 0,

            // details modal
            selectedProject: EmptyProject,
            selectedField: EmptyField,
            selectedReservoir: EmptyReservoir,
        };

        this.handleFieldsResevoirsTabChange = this.handleFieldsResevoirsTabChange.bind(this);
        this.handleProjectsTabChange = this.handleProjectsTabChange.bind(this);
        this.handleReportsTabChange = this.handleReportsTabChange.bind(this);

        this.handleProjectFilterKeyPress = this.handleProjectFilterKeyPress.bind(this);
        this.handleFieldFilterKeyPress = this.handleFieldFilterKeyPress.bind(this);
        this.handleReservoirFilterKeyPress = this.handleReservoirFilterKeyPress.bind(this);

        // control handlers
        this.clearSearch = this.clearSearch.bind(this);
        this.handleProjectsSearch = this.handleProjectsSearch.bind(this);
        this.handleFieldsSearch = this.handleFieldsSearch.bind(this);
        this.handleReservoirsSearch = this.handleReservoirsSearch.bind(this);
        this.handleProjectsCsvExport = this.handleProjectsCsvExport.bind(this);
        this.handleProjectsExcelExport = this.handleProjectsExcelExport.bind(this);
        this.handleFieldsCsvExport = this.handleFieldsCsvExport.bind(this);
        this.handleFieldsExcelExport = this.handleFieldsExcelExport.bind(this);
        this.handleReservoirsCsvExport = this.handleReservoirsCsvExport.bind(this);
        this.handleReservoirsExcelExport = this.handleReservoirsExcelExport.bind(this);
        this.handleClientFilter = this.handleClientFilter.bind(this);
        this.handleCountryFilter = this.handleCountryFilter.bind(this);
        this.handleClientCountryFilter = this.handleClientCountryFilter.bind(this);
        this.handleCountryClientFilter = this.handleCountryClientFilter.bind(this);
        this.handleProjectNameFilter = this.handleProjectNameFilter.bind(this);
        this.handleAsOfDateStartFilter = this.handleAsOfDateStartFilter.bind(this);
        this.handleAsOfDateEndFilter = this.handleAsOfDateEndFilter.bind(this);
        this.handleFieldNameFilter = this.handleFieldNameFilter.bind(this);
        this.handleReservoirNameFilter = this.handleReservoirNameFilter.bind(this);
        this.handleReportsSearch = this.handleReportsSearch.bind(this);
        this.handleCountryClientGroupChange = this.handleCountryClientGroupChange.bind(this);
        this.handleGetClientsForCountry = this.handleGetClientsForCountry.bind(this);
        this.handleGetCountriesForClient = this.handleGetCountriesForClient.bind(this);
        this.handleAssignedProjectsForField = this.handleAssignedProjectsForField.bind(this);
        this.handleAssignedProjectsForReservoir = this.handleAssignedProjectsForReservoir.bind(this);
        this.handleAssignedFieldsReservoirsSearch = this.handleAssignedFieldsReservoirsSearch.bind(this);

        // pagination handlers
        this.projectsTableChangePage = this.projectsTableChangePage.bind(this);
        this.projectReportsTableChangePage = this.projectReportsTableChangePage.bind(this);
        this.fieldsTableChangePage = this.fieldsTableChangePage.bind(this);
        this.reservoirsTableChangePage = this.reservoirsTableChangePage.bind(this);

        this.setDisabledFilters = this.setDisabledFilters.bind(this);

        // custom components
        this.getReportsComponent = this.getReportsComponent.bind(this);
        this.getFoundFieldsComponent = this.getFoundFieldsComponent.bind(this);
        this.getFoundReservoirsComponent = this.getFoundReservoirsComponent.bind(this);
        this.getFoundProjectsComponent = this.getFoundProjectsComponent.bind(this);
        this.getCountryClientAutocomplete = this.getCountryClientAutocomplete.bind(this);
        this.getConnectedClientAutocomplete = this.getConnectedClientAutocomplete.bind(this);
        this.getConnectedCountriesAutocomplete = this.getConnectedCountriesAutocomplete.bind(this);
    }

    async componentDidMount() {
        this.setState({ loadingActive: true });

        const countries = await apiGetCountries({});
        const clients = await apiGetClients();

        this.setState({ loadingActive: false, countries, clients });
    }

    handleFieldsResevoirsTabChange(_: unknown, newValue: number) {
        this.setState({
            fieldsReservoirsTabValue: newValue,
        });
    }

    handleProjectsTabChange(_: unknown, newValue: number) {
        this.setState({
            projectsTabValue: newValue,
        });
    }

    handleReportsTabChange(_: unknown, newValue: number) {
        this.setState({
            reportsTabValue: newValue,
        });
    }

    handleProjectFilterKeyPress(event: KeyboardEvent) {
        if (event.key === "Enter") {
            this.handleProjectsSearch();
        }
    }

    handleFieldFilterKeyPress(event: KeyboardEvent) {
        if (event.key === "Enter") {
            this.handleFieldsSearch();
        }
    }

    handleReservoirFilterKeyPress(event: KeyboardEvent) {
        if (event.key === "Enter") {
            this.handleReservoirsSearch();
        }
    }

    handleCountryFilter(_: unknown, value: Country | null) {
        let val = value !== null ? value.code : "-";

        this.setState({
            filterCountry: val,
            //filterClient: "-",
            filterProjectName: "",
            filterFieldName: "",
            filterFieldTextPos: "C",
            filterReservoirName: "",
            filterReservoirTextPos: "C",
            filterAodStart: null,
            filterAodEnd: null,
        });
    }

    handleCountryClientFilter(_: unknown, value: Country | null) {
        if (value) this.handleGetClientsForCountry(value.code);

        let val = value !== null ? value.code : "-";

        this.setState({
            filterCountry: val,
            filterClient: "-",
            filterProjectName: "",
            filterFieldName: "",
            filterFieldTextPos: "C",
            filterReservoirName: "",
            filterReservoirTextPos: "C",
            filterAodStart: null,
            filterAodEnd: null,
        });
    }

    handleClientFilter(_: unknown, value: Client | null) {
        let val = value !== null ? value.clientCode : "-";

        this.setState({
            //filterCountry: value.code,
            filterClient: val,
            filterProjectName: "",
            filterFieldName: "",
            filterFieldTextPos: "C",
            filterReservoirName: "",
            filterReservoirTextPos: "C",
            filterAodStart: null,
            filterAodEnd: null,
        });
    }

    handleClientCountryFilter(_: unknown, value: Client | null) {
        if (value) this.handleGetCountriesForClient(value.clientCode);

        let val = value !== null ? value.clientCode : "-";

        this.setState({
            filterCountry: "-",
            filterClient: val,
            filterProjectName: "",
            filterFieldName: "",
            filterFieldTextPos: "C",
            filterReservoirName: "",
            filterReservoirTextPos: "C",
            filterAodStart: null,
            filterAodEnd: null,
        });
    }

    handleProjectNameFilter(event: ChangeEvent<HTMLInputElement>) {
        this.setState({
            filterCountry: "-",
            filterClient: "-",
            filterFieldName: "",
            filterFieldTextPos: "C",
            filterReservoirName: "",
            filterReservoirTextPos: "C",
            filterAodStart: null,
            filterAodEnd: null,
            filterProjectName: event.target.value,
        });
    }

    handleAsOfDateStartFilter(value: MaterialUiPickersDate) {
        this.setState({
            filterCountry: "-",
            filterClient: "-",
            filterProjectName: "",
            filterFieldName: "",
            filterFieldTextPos: "C",
            filterReservoirName: "",
            filterReservoirTextPos: "C",
            filterAodStart: value,
        });
    }

    handleAsOfDateEndFilter(value: MaterialUiPickersDate) {
        this.setState({
            filterCountry: "-",
            filterClient: "-",
            filterProjectName: "",
            filterFieldName: "",
            filterFieldTextPos: "C",
            filterReservoirName: "",
            filterReservoirTextPos: "C",
            filterAodEnd: value,
        });
    }

    handleFieldNameFilter(event: ChangeEvent<HTMLInputElement>) {
        this.setState({
            filterCountry: "-",
            filterClient: "-",
            filterProjectName: "",
            filterReservoirName: "",
            filterAodStart: null,
            filterAodEnd: null,

            filterFieldName: event.target.value,
        });
    }

    handleReservoirNameFilter(event: ChangeEvent<HTMLInputElement>) {
        this.setState({
            filterCountry: "-",
            filterClient: "-",
            filterProjectName: "",
            filterAodStart: null,
            filterAodEnd: null,

            filterFieldName: "",
            filterReservoirName: event.target.value,
        });
    }

    handleCountryClientGroupChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({
            filterCountry: "-",
            filterClient: "-",
            countryClientSelectedOption: event.target.value,
        });
    }

    componentDidUpdate(_: unknown, prevState: LegacySearchState) {
        // this.setDisabledFilters();

        if (
            this.state.filterProjectName !== prevState.filterProjectName ||
            this.state.filterFieldName !== prevState.filterFieldName ||
            this.state.filterReservoirName !== prevState.filterReservoirName ||
            this.state.filterAodStart !== prevState.filterAodStart ||
            this.state.filterAodEnd !== prevState.filterAodEnd ||
            this.state.filterCountry !== prevState.filterCountry ||
            this.state.filterClient !== prevState.filterClient
        ) {
            this.setDisabledFilters();
        }
    }

    setDisabledFilters() {
        let cnt = "";
        let cli = "";
        if (this.state.filterCountry) cnt = this.state.filterCountry.trim();
        if (this.state.filterClient) cli = this.state.filterClient.trim();

        if (cnt === "-") cnt = "";
        if (cli === "-") cli = "";

        let proj = this.state.filterProjectName.trim();
        let fld = this.state.filterFieldName.trim();
        let res = this.state.filterReservoirName.trim();
        let aodS = this.state.filterAodStart;
        let aodE = this.state.filterAodEnd;

        this.setState({
            countryFilterDisabled: false,
            filterCountryVisible: true,
            projectFilterDisabled: false,
            fieldFilterDisabled: false,
            reservoirFilterDisabled: false,
            projectAsOfDateFilterDisabled: false,
        });

        if (cnt.length > 0 || cli.length > 0) {
            this.setState({
                projectFilterDisabled: true,
                countryFilterDisabled: false,
                filterCountryVisible: true,
                fieldFilterDisabled: true,
                reservoirFilterDisabled: true,
                projectAsOfDateFilterDisabled: true,
            });
        }

        if (proj.length > 0) {
            this.setState({
                countryFilterDisabled: true,
                filterCountryVisible: false,
                fieldFilterDisabled: true,
                reservoirFilterDisabled: true,
                projectAsOfDateFilterDisabled: true,
            });
        }

        if (fld.length > 0) {
            this.setState({
                countryFilterDisabled: true,
                filterCountryVisible: false,
                projectFilterDisabled: true,
                reservoirFilterDisabled: true,
                projectAsOfDateFilterDisabled: true,

                filterCountry: "-",
                filterClient: "-",
                countryClientSelectedOption: "country",
            });
        }

        if (res.length > 0) {
            this.setState({
                countryFilterDisabled: true,
                filterCountryVisible: false,
                projectFilterDisabled: true,
                fieldFilterDisabled: true,
                projectAsOfDateFilterDisabled: true,

                filterCountry: "-",
                filterClient: "-",
                countryClientSelectedOption: "country",
            });
        }

        if (aodS || aodE) {
            this.setState({
                countryFilterDisabled: true,
                filterCountryVisible: false,
                projectFilterDisabled: true,
                fieldFilterDisabled: true,
                reservoirFilterDisabled: true,
            });
        }
    }

    clearSearch() {
        this.setState({
            countryClientSelectedOption: "country",
            filterCountryVisible: true,
            filterCountry: "-",
            filterClient: "-",
            filterProjectName: "",
            filterFieldName: "",
            filterFieldTextPos: "C",
            filterReservoirName: "",
            filterReservoirTextPos: "C",
            filterAodStart: null,
            filterAodEnd: null,
        });
    }

    async handleProjectsSearch() {
        this.setState({ loadingActive: true });

        const projects = await apiGetProjects({
            codeOrNameContains: this.state.filterProjectName,
            asOfDateFrom: this.state.filterAodStart?.toISOString().split("T")[0],
            asOfDateTo: this.state.filterAodEnd?.toISOString().split("T")[0],
            countryCode: this.state.filterCountry !== "-" ? this.state.filterCountry : undefined,
            clientCode: this.state.filterClient !== "-" ? this.state.filterClient : undefined,
        });

        this.setState({
            loadingActive: false,
            projectsFilter: {
                codeOrNameContains: this.state.filterProjectName,
                asOfDateFrom: this.state.filterAodStart,
                asOfDateTo: this.state.filterAodEnd,
                countryCode: this.state.filterCountry !== "-" ? this.state.filterCountry : undefined,
                clientCode: this.state.filterClient !== "-" ? this.state.filterClient : undefined,
            },
            fieldsFilter: null,
            reservoirsFilter: null,
            projects: projects,
            fields: [],
            reservoirs: [],
            projectReports: [],
            projectReportsTablePage: 0,
            projectsTablePage: 0,
            fieldsTablePage: 0,
            reservoirsTablePage: 0,
        });
    }

    async handleFieldsSearch() {
        const field = this.state.filterFieldName;
        const ftp = this.state.filterFieldTextPos;

        this.setState({ loadingActive: true });

        const fields = await apiGetFields({
            codeOrNameStartsWith: ftp === "S" ? field : undefined,
            codeOrNameContains: ftp === "C" ? field : undefined,
            codeOrNameEndsWith: ftp === "E" ? field : undefined,
            includeDeleted: true,
        });

        this.setState({
            loadingActive: false,
            projectsFilter: null,
            fieldsFilter: {
                codeOrNameStartsWith: ftp === "S" ? field : undefined,
                codeOrNameContains: ftp === "C" ? field : undefined,
                codeOrNameEndsWith: ftp === "E" ? field : undefined,
                includeDeleted: true,
            },
            reservoirsFilter: null,
            projects: [],
            fields: fields,
            reservoirs: [],
            projectReports: [],
            projectReportsTablePage: 0,
            projectsTablePage: 0,
            fieldsTablePage: 0,
            reservoirsTablePage: 0,
            fieldsReservoirsTabValue: 0,
        });
    }

    async handleReservoirsSearch() {
        const reservoir = this.state.filterReservoirName;
        const rtp = this.state.filterReservoirTextPos;

        this.setState({ loadingActive: true });

        const reservoirs = await apiGetReservoirs({
            codeOrNameStartsWith: rtp === "S" ? reservoir : undefined,
            codeOrNameContains: rtp === "C" ? reservoir : undefined,
            codeOrNameEndsWith: rtp === "E" ? reservoir : undefined,
            includeDeleted: true,
        });

        this.setState({
            loadingActive: false,
            projectsFilter: null,
            fieldsFilter: null,
            reservoirsFilter: {
                codeOrNameStartsWith: rtp === "S" ? reservoir : undefined,
                codeOrNameContains: rtp === "C" ? reservoir : undefined,
                codeOrNameEndsWith: rtp === "E" ? reservoir : undefined,
                includeDeleted: true,
            },
            projects: [],
            fields: [],
            reservoirs: reservoirs,
            projectReports: [],
            projectReportsTablePage: 0,
            projectsTablePage: 0,
            fieldsTablePage: 0,
            reservoirsTablePage: 0,
            fieldsReservoirsTabValue: 1,
        });
    }

    handleProjectsCsvExport() {
        if (!this.state.projectsFilter) return;
        apiExportProjectsAsCsv(this.state.projectsFilter)
            .then((blob) => saveBlob(blob, "Projects.csv"))
            .catch((e) => console.error(e.message));
    }

    handleProjectsExcelExport() {
        if (!this.state.projectsFilter) return;
        apiExportProjectsAsExcel(this.state.projectsFilter)
            .then((blob) => saveBlob(blob, "Projects.xlsx"))
            .catch((e) => console.error(e.message));
    }

    handleFieldsCsvExport() {
        if (!this.state.fieldsFilter) return;
        apiExportFieldsAsCsv(this.state.fieldsFilter)
            .then((blob) => saveBlob(blob, "Fields.csv"))
            .catch((e) => console.error(e.message));
    }

    handleFieldsExcelExport() {
        if (!this.state.fieldsFilter) return;
        apiExportFieldsAsExcel(this.state.fieldsFilter)
            .then((blob) => saveBlob(blob, "Fields.xlsx"))
            .catch((e) => console.error(e.message));
    }

    handleReservoirsCsvExport() {
        if (!this.state.reservoirsFilter) return;
        apiExportReservoirsAsCsv(this.state.reservoirsFilter)
            .then((blob) => saveBlob(blob, "Reservoirs.csv"))
            .catch((e) => console.error(e.message));
    }

    handleReservoirsExcelExport() {
        if (!this.state.reservoirsFilter) return;
        apiExportReservoirsAsExcel(this.state.reservoirsFilter)
            .then((blob) => saveBlob(blob, "Reservoirs.xlsx"))
            .catch((e) => console.error(e.message));
    }

    async handleReportsSearch(project: Project) {
        this.setState({ loadingActive: true });

        const reports = await apiGetProjectReports(project.code);

        this.setState({ loadingActive: false, projectReports: reports });
    }

    async handleGetClientsForCountry(country: string) {
        this.setState({
            loadingActive: true,
            countryClientsKey: "countryClientsAutocomplete" + new Date().toLocaleTimeString(),
        });

        const clients = await apiGetClientsForCountry(country);

        this.setState({ loadingActive: false, countryClients: clients });
    }

    async handleGetCountriesForClient(client: string) {
        this.setState({
            loadingActive: true,
            clientCountriesKey: "clientCountriesAutocomplete" + new Date().toLocaleTimeString(),
        });

        const countries = await apiGetCountries({ clientCode: client });

        this.setState({ loadingActive: false, clientCountries: countries });
    }

    async handleAssignedProjectsForField(field: number) {
        this.setState({ loadingActive: true });

        const projects = await apiGetProjects({ fieldId: field });

        this.setState({ loadingActive: false, projectsFilter: { fieldId: field }, projects: projects });
    }

    async handleAssignedProjectsForReservoir(reservoir: number) {
        this.setState({ loadingActive: true });

        const projects = await apiGetProjects({ reservoirId: reservoir });

        this.setState({ loadingActive: false, projectsFilter: { reservoirId: reservoir }, projects: projects });
    }

    async handleAssignedFieldsReservoirsSearch(projNo: string) {
        this.setState({ loadingActive: true });

        const fields = await apiGetFields({ projectNo: projNo, includeDeleted: true });
        const reservoirs = await apiGetReservoirs({ projectNo: projNo, includeDeleted: true });

        this.setState({
            loadingActive: false,
            fieldsFilter: { projectNo: projNo, includeDeleted: true },
            reservoirsFilter: { projectNo: projNo, includeDeleted: true },
            fields: fields,
            reservoirs: reservoirs,
        });
    }

    // region pagination

    projectsTableChangePage(_: unknown, newPage: number) {
        this.setState({ projectsTablePage: newPage });
    }

    projectReportsTableChangePage(_: unknown, newPage: number) {
        this.setState({ projectReportsTablePage: newPage });
    }

    fieldsTableChangePage(_: unknown, newPage: number) {
        this.setState({ fieldsTablePage: newPage });
    }

    reservoirsTableChangePage(_: unknown, newPage: number) {
        this.setState({ reservoirsTablePage: newPage });
    }

    // - end region

    render() {
        return (
            <LoadingOverlay active={this.state.loadingActive} spinner text="Please wait...">
                <Container component="main" maxWidth="xl">
                    <Box marginTop="3rem" />

                    <Grid container spacing={4}>
                        <Grid item xs={2} component={Paper} elevation={0} square style={{ padding: 0 }}>
                            <Accordion disabled={this.state.countryFilterDisabled}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                        Country/Client
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {!this.state.countryFilterDisabled && (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    name="countryClientGroup"
                                                    onChange={this.handleCountryClientGroupChange}
                                                >
                                                    <FormControlLabel
                                                        label="Country"
                                                        value="country"
                                                        control={<Radio />}
                                                        checked={this.state.countryClientSelectedOption === "country"}
                                                    />
                                                    <FormControlLabel
                                                        disabled={
                                                            this.state.filterFieldName.length > 0 ||
                                                            this.state.filterReservoirName.length > 0
                                                        }
                                                        label="Client"
                                                        value="client"
                                                        control={<Radio />}
                                                        checked={this.state.countryClientSelectedOption === "client"}
                                                    />
                                                    <FormControlLabel
                                                        disabled={
                                                            this.state.filterFieldName.length > 0 ||
                                                            this.state.filterReservoirName.length > 0
                                                        }
                                                        label="Country/Client"
                                                        value="country-client"
                                                        control={<Radio />}
                                                        checked={
                                                            this.state.countryClientSelectedOption === "country-client"
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        disabled={
                                                            this.state.filterFieldName.length > 0 ||
                                                            this.state.filterReservoirName.length > 0
                                                        }
                                                        label="Client/Country"
                                                        value="client-country"
                                                        control={<Radio />}
                                                        checked={
                                                            this.state.countryClientSelectedOption === "client-country"
                                                        }
                                                    />
                                                </RadioGroup>
                                                <Box marginBottom="1rem" />
                                            </Grid>

                                            {this.getCountryClientAutocomplete()}

                                            <Grid item xs={12}>
                                                <Box marginBottom="1rem" />

                                                <Button
                                                    fullWidth
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={this.handleProjectsSearch}
                                                >
                                                    Search &nbsp;&nbsp;&nbsp;
                                                    <SearchIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>

                            <Accordion disabled={this.state.projectFilterDisabled}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                        Project
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {!this.state.projectFilterDisabled && (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="standard"
                                                    size="small"
                                                    margin="dense"
                                                    fullWidth
                                                    label="Project No/Name"
                                                    onChange={this.handleProjectNameFilter}
                                                    value={this.state.filterProjectName}
                                                    onKeyDown={this.handleProjectFilterKeyPress}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box marginBottom="1rem" />

                                                <Button
                                                    fullWidth
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={this.handleProjectsSearch}
                                                >
                                                    Search &nbsp;&nbsp;&nbsp;
                                                    <SearchIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    disabled={this.state.projectAsOfDateFilterDisabled}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                        Project as of date
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {!this.state.projectAsOfDateFilterDisabled && (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="dense"
                                                        label="From"
                                                        format="MM/dd/yyyy"
                                                        maxDate={new Date(new Date().getFullYear() + 5, 1, 1)}
                                                        minDate={new Date("2008-01-01")}
                                                        clearable
                                                        value={this.state.filterAodStart}
                                                        //onChange={(value) => { this.setState({ filterAodStart: value }) }}
                                                        onChange={this.handleAsOfDateStartFilter}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                        onKeyDown={this.handleProjectFilterKeyPress}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="dense"
                                                        label="To"
                                                        format="MM/dd/yyyy"
                                                        maxDate={new Date(new Date().getFullYear() + 5, 1, 1)}
                                                        minDate={new Date("2008-01-01")}
                                                        clearable
                                                        value={this.state.filterAodEnd}
                                                        //onChange={(value) => { this.setState({ filterAodEnd: value }) }}
                                                        onChange={this.handleAsOfDateEndFilter}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                        onKeyDown={this.handleProjectFilterKeyPress}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box marginBottom="1rem" />

                                                <Button
                                                    fullWidth
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={this.handleProjectsSearch}
                                                >
                                                    Search &nbsp;&nbsp;&nbsp;
                                                    <SearchIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>

                            <Accordion disabled={this.state.fieldFilterDisabled}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                        Fields
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {!this.state.fieldFilterDisabled && (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="standard"
                                                    margin="dense"
                                                    fullWidth
                                                    label="Field Name/Code"
                                                    onChange={this.handleFieldNameFilter}
                                                    value={this.state.filterFieldName}
                                                    onKeyDown={this.handleFieldFilterKeyPress}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box marginBottom="10px" />
                                                <FormControl style={{ minWidth: "100%" }}>
                                                    <InputLabel id="label-field-textsearchpos">
                                                        Text Search Position
                                                    </InputLabel>

                                                    <Select
                                                        labelId="label-field-textsearchpos"
                                                        value={this.state.filterFieldTextPos}
                                                        variant="standard"
                                                        margin="dense"
                                                        fullWidth
                                                        onChange={(e) => {
                                                            this.setState({
                                                                filterFieldTextPos: e.target.value as "S" | "C" | "E",
                                                            });
                                                        }}
                                                    >
                                                        <MenuItem value="S">Starts with</MenuItem>
                                                        <MenuItem value="C">Contains</MenuItem>
                                                        <MenuItem value="E">Ends with</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box marginBottom="1rem" />

                                                <Button
                                                    fullWidth
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={this.handleFieldsSearch}
                                                >
                                                    Search &nbsp;&nbsp;&nbsp;
                                                    <SearchIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>

                            <Accordion disabled={this.state.reservoirFilterDisabled}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                        Reservoirs
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {!this.state.reservoirFilterDisabled && (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="standard"
                                                    margin="dense"
                                                    fullWidth
                                                    label="Reservoir Name/Code"
                                                    onChange={this.handleReservoirNameFilter}
                                                    value={this.state.filterReservoirName}
                                                    onKeyDown={this.handleReservoirFilterKeyPress}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box marginBottom="10px" />
                                                <FormControl style={{ minWidth: "100%" }}>
                                                    <InputLabel id="label-reservoir-textsearchpos">
                                                        Text Search Position
                                                    </InputLabel>

                                                    <Select
                                                        labelId="label-reservoir-textsearchpos"
                                                        value={this.state.filterReservoirTextPos}
                                                        variant="standard"
                                                        margin="dense"
                                                        fullWidth
                                                        onChange={(e) => {
                                                            this.setState({
                                                                filterReservoirTextPos: e.target.value as
                                                                    | "S"
                                                                    | "C"
                                                                    | "E",
                                                            });
                                                        }}
                                                    >
                                                        <MenuItem value="S">Starts with</MenuItem>
                                                        <MenuItem value="C">Contains</MenuItem>
                                                        <MenuItem value="E">Ends with</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box marginBottom="1rem" />

                                                <Button
                                                    fullWidth
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={this.handleReservoirsSearch}
                                                >
                                                    Search &nbsp;&nbsp;&nbsp;
                                                    <SearchIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>

                            <Box marginTop="3rem" />

                            <Divider />

                            <Box p={1}>
                                <Button fullWidth color="secondary" variant="outlined" onClick={this.clearSearch}>
                                    Clear Search Filter &nbsp;&nbsp;&nbsp;
                                    <ClearAllIcon fontSize="small" />
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item xs={8} component={Paper} variant="outlined" elevation={4}>
                            <Tabs
                                variant="fullWidth"
                                value={this.state.projectsTabValue}
                                onChange={this.handleProjectsTabChange}
                            >
                                <Tab label="Found Projects" />
                            </Tabs>

                            <TabPanel value={this.state.projectsTabValue} index={0} style={{ fontSize: "0.85rem" }}>
                                <ProjectInfoDialog
                                    open={this.state.projectDetailsOpen}
                                    project={this.state.selectedProject}
                                    onClose={() => {
                                        this.setState({ projectDetailsOpen: false });
                                    }}
                                />
                                {this.getFoundProjectsComponent()}
                            </TabPanel>

                            <Box marginTop={3} />

                            <Divider />

                            <Grid container>
                                <Grid item xs={6}>
                                    <Tabs
                                        variant="fullWidth"
                                        value={this.state.fieldsReservoirsTabValue}
                                        onChange={this.handleFieldsResevoirsTabChange}
                                    >
                                        <Tab label="Fields" />
                                        <Tab label="Reservoirs" />
                                    </Tabs>
                                </Grid>
                            </Grid>

                            <TabPanel
                                value={this.state.fieldsReservoirsTabValue}
                                index={0}
                                style={{ fontSize: "0.85rem" }}
                            >
                                <FieldInfoDialog
                                    open={this.state.fieldDetailsOpen}
                                    field={this.state.selectedField}
                                    onClose={() => {
                                        this.setState({ fieldDetailsOpen: false });
                                    }}
                                />
                                {this.getFoundFieldsComponent()}
                            </TabPanel>

                            <TabPanel
                                value={this.state.fieldsReservoirsTabValue}
                                index={1}
                                style={{ fontSize: "0.85rem" }}
                            >
                                <ReservoirInfoDialog
                                    open={this.state.reservoirsDetailsOpen}
                                    reservoir={this.state.selectedReservoir}
                                    onClose={() => {
                                        this.setState({ reservoirsDetailsOpen: false });
                                    }}
                                />

                                {this.getFoundReservoirsComponent()}
                            </TabPanel>
                        </Grid>

                        <Grid item xs={2} component={Paper} square elevation={0}>
                            {this.getReportsComponent()}
                        </Grid>
                    </Grid>
                </Container>
            </LoadingOverlay>
        );
    }

    // custom components

    getReportsComponent() {
        if (this.state.projectReports.length === 0) {
            return <Alert severity="info">No reports found.</Alert>;
        }

        return (
            <>
                <Grid container>
                    <Grid item xs={6}>
                        <Tabs
                            variant="fullWidth"
                            value={this.state.reportsTabValue}
                            onChange={this.handleReportsTabChange}
                        >
                            <Tab label="Found Reports" />
                        </Tabs>
                    </Grid>
                </Grid>

                <TabPanel value={this.state.reportsTabValue} index={0}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Report Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.projectReports
                                .slice(
                                    this.state.projectReportsTablePage * this.state.rowsPerPage,
                                    this.state.projectReportsTablePage * this.state.rowsPerPage + this.state.rowsPerPage
                                )
                                .map((report) => {
                                    return (
                                        <TableRow key={"tr_" + report.reportName}>
                                            <TableCell>
                                                <Typography variant="caption" component="span">
                                                    <Link target="_blank" rel="noopener" href={report.reportLink}>
                                                        {report.reportName}
                                                    </Link>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={1}
                                    count={this.state.projectReports.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.projectReportsTablePage}
                                    onPageChange={this.projectReportsTableChangePage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TabPanel>

                <Box height={30} />
            </>
        );
    }

    getFoundFieldsComponent() {
        return (
            <MaterialTable
                columns={[
                    { title: "Code", field: "code" },
                    { title: "Name", field: "name" },
                    { title: "Country", field: "countryName" },
                    { title: "Basin", field: "barName" },
                    { title: "Deleted", field: "isDeleted", render: (row) => (row.isDeleted ? "Yes" : "No") },
                    {
                        title: "",
                        render: (row) =>
                            row.hasProjects ? (
                                <Link
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.handleAssignedProjectsForField(row.id);
                                    }}
                                >
                                    Assigned Projects
                                </Link>
                            ) : (
                                <div>No Assigned Projects</div>
                            ),
                    },
                    {
                        title: "",
                        render: (row) => (
                            <Link
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        selectedField: row,
                                        fieldDetailsOpen: true,
                                    });
                                }}
                            >
                                Details...
                            </Link>
                        ),
                    },
                ]}
                data={this.state.fields}
                options={{
                    filtering: false,
                    toolbar: false,
                    draggable: false,
                    headerStyle: { position: "sticky", top: 0 },
                    emptyRowsWhenPaging: false,
                    pageSize: 20,
                    pageSizeOptions: [10, 20, 30],
                    tableLayout: "auto",
                    loadingType: "linear",
                    debounceInterval: 450,
                    padding: "dense",
                }}
                components={{
                    Pagination: (props) => (
                        <TableCell padding="none" style={{ display: "flex", alignItems: "center" }}>
                            {this.state.fields != null && this.state.fields.length > 0 && (
                                <>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        style={{ marginLeft: 16 }}
                                        onClick={this.handleFieldsCsvExport}
                                    >
                                        Export CSV
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        style={{ marginLeft: 16 }}
                                        onClick={this.handleFieldsExcelExport}
                                    >
                                        Export Excel
                                    </Button>
                                </>
                            )}
                            <div style={{ flexGrow: 1 }} />
                            <TablePagination component="div" {...props} />
                        </TableCell>
                    ),
                }}
            />
        );
    }

    getFoundReservoirsComponent() {
        return (
            <MaterialTable
                columns={[
                    { title: "Code", field: "code" },
                    { title: "Name", field: "name" },
                    { title: "Basin", field: "barName" },
                    { title: "Country", field: "countryName" },
                    { title: "Deleted", field: "isDeleted", render: (row) => (row.isDeleted ? "Yes" : "No") },
                    {
                        title: "",
                        render: (row) =>
                            row.hasProjects ? (
                                <Link
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.handleAssignedProjectsForReservoir(row.id);
                                    }}
                                >
                                    Assigned Projects
                                </Link>
                            ) : (
                                <div>No Assigned Projects</div>
                            ),
                    },
                    {
                        title: "",
                        render: (row) => (
                            <Link
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        selectedReservoir: row,
                                        reservoirsDetailsOpen: true,
                                    });
                                }}
                            >
                                Details...
                            </Link>
                        ),
                    },
                ]}
                data={this.state.reservoirs}
                options={{
                    filtering: false,
                    toolbar: false,
                    draggable: false,
                    headerStyle: { position: "sticky", top: 0 },
                    emptyRowsWhenPaging: false,
                    pageSize: 20,
                    pageSizeOptions: [10, 20, 30],
                    tableLayout: "auto",
                    loadingType: "linear",
                    debounceInterval: 450,
                    padding: "dense",
                }}
                components={{
                    Pagination: (props) => (
                        <TableCell padding="none" style={{ display: "flex", alignItems: "center" }}>
                            {this.state.reservoirs != null && this.state.reservoirs.length > 0 && (
                                <>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        style={{ marginLeft: 16 }}
                                        onClick={this.handleReservoirsCsvExport}
                                    >
                                        Export CSV
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        style={{ marginLeft: 16 }}
                                        onClick={this.handleReservoirsExcelExport}
                                    >
                                        Export Excel
                                    </Button>
                                </>
                            )}
                            <div style={{ flexGrow: 1 }} />
                            <TablePagination component="div" {...props} />
                        </TableCell>
                    ),
                }}
            />
        );
    }

    getFoundProjectsComponent() {
        return (
            <MaterialTable
                columns={[
                    {
                        title: "Project Number",
                        field: "code",
                        width: "230px",
                        render: (row) => (
                            <Link
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.handleAssignedFieldsReservoirsSearch(row.code);
                                }}
                            >
                                {row.code}
                            </Link>
                        ),
                    },
                    {
                        title: "Project Name",
                        field: "name",
                        width: "400px",
                    },
                    { title: "Division", field: "division" },
                    { title: "Status", field: "status" },
                    {
                        title: "",
                        render: (row) => (
                            <Link
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.handleReportsSearch(row);
                                }}
                            >
                                Reports
                            </Link>
                        ),
                    },
                    {
                        title: "",
                        render: (row) => (
                            <Link
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        selectedProject: row,
                                        projectDetailsOpen: true,
                                    });
                                }}
                            >
                                Details...
                            </Link>
                        ),
                    },
                ]}
                data={this.state.projects}
                options={{
                    filtering: false,
                    toolbar: false,
                    draggable: false,
                    headerStyle: { position: "sticky", top: 0 },
                    emptyRowsWhenPaging: false,
                    pageSize: 20,
                    pageSizeOptions: [10, 20, 30],
                    tableLayout: "auto",
                    loadingType: "linear",
                    debounceInterval: 450,
                    padding: "dense",
                }}
                components={{
                    Pagination: (props) => (
                        <TableCell padding="none" style={{ display: "flex", alignItems: "center" }}>
                            {this.state.projects != null && this.state.projects.length > 0 && (
                                <>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        style={{ marginLeft: 16 }}
                                        onClick={this.handleProjectsCsvExport}
                                    >
                                        Export CSV
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        style={{ marginLeft: 16 }}
                                        onClick={this.handleProjectsExcelExport}
                                    >
                                        Export Excel
                                    </Button>
                                </>
                            )}
                            <div style={{ flexGrow: 1 }} />
                            <TablePagination component="div" {...props} />
                        </TableCell>
                    ),
                }}
            />
        );
    }

    getConnectedClientAutocomplete() {
        let val = this.state.filterCountry;
        if (val === "-" || val.length === 0) return <></>;

        return (
            <Autocomplete
                // client!
                //onChange={(event, value) => { this.setState({ filterClient: (value !== null) ? value.clientCode : "-" }) }}
                onChange={this.handleClientFilter}
                autoHighlight
                options={this.state.countryClients}
                getOptionLabel={(option) => option.clientName}
                key={this.state.countryClientsKey}
                renderOption={(option) => (
                    <Typography variant="body2" component="span">
                        {option.clientName}{" "}
                    </Typography>
                )}
                renderInput={(params) => (
                    <TextField
                        name="textFieldClient2"
                        key="textfieldclient2-key"
                        margin="dense"
                        {...params}
                        label="Choose a client"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                    />
                )}
                onKeyDown={this.handleProjectFilterKeyPress}
            />
        );
    }

    getConnectedCountriesAutocomplete() {
        let val = this.state.filterClient;
        if (val === "-" || val.length === 0) return <></>;

        return (
            <Autocomplete
                // client!

                //onChange={(event, value) => { this.setState({ filterCountry: (value !== null) ? value.coCode : "-" }) }}
                onChange={this.handleCountryFilter}
                autoHighlight
                options={this.state.clientCountries}
                getOptionLabel={(option) => option.name}
                key={this.state.clientCountriesKey}
                renderOption={(option) => (
                    <Typography variant="body2" component="span">
                        {option.name}{" "}
                    </Typography>
                )}
                renderInput={(params) => (
                    <TextField
                        key="textfieldountry4-key"
                        margin="dense"
                        {...params}
                        label="Choose a Country"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                    />
                )}
                onKeyDown={this.handleProjectFilterKeyPress}
            />
        );
    }

    getCountryClientAutocomplete() {
        if (!this.state.filterCountryVisible) return <Fragment />;

        switch (this.state.countryClientSelectedOption) {
            case "country":
            default:
                return (
                    <Grid item xs={12}>
                        <Autocomplete
                            // country!
                            //onChange={(event, value) => { this.setState({ filterCountry: (value !== null) ? value.code : "-" }) }}
                            onChange={this.handleCountryFilter}
                            autoHighlight
                            options={this.state.countries}
                            getOptionLabel={(option) => option.name}
                            key="autocompletecountry-key"
                            renderOption={(option) => (
                                <Typography variant="body2" component="span">
                                    {option.name}{" "}
                                </Typography>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    name="textFieldCountry"
                                    key="textfieldcountry-key"
                                    margin="dense"
                                    {...params}
                                    label="Choose a country"
                                    variant="outlined"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                            onKeyDown={this.handleProjectFilterKeyPress}
                        />
                    </Grid>
                );

            case "client":
                return (
                    <Grid item xs={12}>
                        <Autocomplete
                            // client!
                            //onChange={(event, value) => { this.setState({ filterClient: (value !== null) ? value.clientCode : "-" }) }}
                            onChange={this.handleClientFilter}
                            autoHighlight
                            options={this.state.clients}
                            getOptionLabel={(option) => option.clientName}
                            key="autocompleteclient-key"
                            renderOption={(option) => (
                                <Typography variant="body2" component="span">
                                    {option.clientName}{" "}
                                </Typography>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    name="textFieldClient"
                                    key="textfieldclient-key"
                                    margin="dense"
                                    {...params}
                                    label="Choose a client"
                                    variant="outlined"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                            onKeyDown={this.handleProjectFilterKeyPress}
                        />
                    </Grid>
                );

            case "country-client":
                return (
                    <>
                        <Grid item xs={12}>
                            <Autocomplete
                                // country!
                                //onChange={(event, value) => { this.handleGetClientsForCountry(value.code); this.setState({ filterCountry: (value !== null) ? value.code : "-" }) }}
                                onChange={this.handleCountryClientFilter}
                                autoHighlight
                                options={this.state.countries}
                                getOptionLabel={(option) => option.name}
                                key="autocompletecountry2-key"
                                renderOption={(option) => (
                                    <Typography variant="body2" component="span">
                                        {option.name}{" "}
                                    </Typography>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        name="textFieldCountry2"
                                        key="textfieldcountry2-key"
                                        margin="dense"
                                        {...params}
                                        label="Choose a country"
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {this.getConnectedClientAutocomplete()}
                        </Grid>
                    </>
                );

            case "client-country":
                return (
                    <>
                        <Grid item xs={12}>
                            <Autocomplete
                                // client!
                                //onChange={(event, value) => { this.handleGetCountriesForClient(value.clientCode); this.setState({ filterClient: (value !== null) ? value.clientCode : "-" }) }}
                                onChange={this.handleClientCountryFilter}
                                autoHighlight
                                options={this.state.clients}
                                getOptionLabel={(option) => option.clientName}
                                key="autocompleteclient3"
                                renderOption={(option) => (
                                    <Typography variant="body2" component="span">
                                        {option.clientName}{" "}
                                    </Typography>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        name="textFieldClient3"
                                        key="textfieldclient3"
                                        margin="dense"
                                        {...params}
                                        label="Choose a client"
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {this.getConnectedCountriesAutocomplete()}
                        </Grid>
                    </>
                );
        }
    }
    // -- end of custom components
}

interface TabPanelProps {
    style?: CSSProperties;
    children: ReactNode;
    value: number;
    index: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box paddingTop={2} paddingBottom={1}>
                {children}
            </Box>
        </Typography>
    );
}

export default LegacySearch;
