import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CSSProperties, ReactElement, useEffect, useState } from "react";
import { apiGetProjects } from "../api";
import { Project, ProjectOrder } from "../types";

interface SelectProjectProps {
  style?: CSSProperties;
  projectCode: string;
  onChange: (projectCode: string) => void;
  hasFields?: boolean;
  hasReservoirs?: boolean;
  showCount?: "field" | "reservoir";
}

export default function SelectProject(props: SelectProjectProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [projects, setProjects] = useState<Project[]>([]);
  const [selected, setSelected] = useState<Project | null>(null);

  useEffect(() => {
    if (!isOpen && !userInput && !props.projectCode) return;

    // We already have this project, no need to (re)load
    if (projects.some((x) => x.code === props.projectCode)) return;

    const timeout = setTimeout(() => {
      setIsLoading(true);
      apiGetProjects({
        codeOrNameContains: userInput || props.projectCode,
        hasFields: props.hasFields,
        hasReservoirs: props.hasReservoirs,
        order: ProjectOrder.CODE_DESC,
        take: 100,
      })
        .then((data) => {
          setIsLoading(false);
          setProjects(data);
        })
        .catch(() => {
          setIsLoading(false);
          setProjects([]);
        });
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.projectCode,
    props.hasFields,
    props.hasReservoirs,
    isOpen,
    userInput,
  ]);

  useEffect(() => {
    setSelected(projects.find((x) => x.code === props.projectCode) || null);
  }, [props.projectCode, projects]);

  const formatLabel = (project: Project) => {
    const label = project.code + " " + project.name;
    switch (props.showCount) {
      case "field":
        return label + ` (${project.fieldCount})`;
      case "reservoir":
        return label + ` (${project.reservoirCount})`;
      default:
        return label;
    }
  };

  return (
    <Autocomplete
      size="small"
      loading={isLoading}
      options={projects}
      value={selected}
      getOptionLabel={formatLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Project"
          placeholder="Filter by project"
          InputLabelProps={{ shrink: true }}
        />
      )}
      style={props.style}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onChange={(_, value) => {
        setSelected(value);
        props.onChange(value?.code || "");
      }}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setUserInput(value);
        }
      }}
    />
  );
}
