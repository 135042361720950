import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeView from "@material-ui/lab/TreeView";
import { ReactElement, useEffect, useState } from "react";
import {
  apiGetBasinsAreasRegions,
  apiGetCountries,
  apiGetFields,
  apiGetProjectFields,
  apiGetProjectReservoirs,
  apiGetReservoirs,
  apiSaveProjectProperties,
} from "../api";
import { useScreenHeight } from "../hooks";
import { Basin, Country, Field, Reservoir } from "../types";
import AlertDialog from "./AlertDialog";
import CountryNode from "./MainForm/CountryBasin/CountryNode";
import ProjectEditToolbar from "./ProjectEditToolbar";

interface CountryBasinsProps {
  projectCode: string;
  projectName: string;
  editingEnabled: boolean;
  onProjectPropertiesUpdate: (
    projectCode: string,
    fieldCount: number,
    reservoirCount: number
  ) => void;
}

export default function CountryBasins(props: CountryBasinsProps): ReactElement {
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [isLoadingProjectFields, setIsLoadingProjectFields] = useState(false);
  const [isLoadingProjectReservoirs, setIsLoadingProjectReservoirs] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [countries, setCountries] = useState<Country[]>([]);
  const [countryBasins, setCountryBasins] = useState<{
    [coCode: string]: Basin[] | undefined;
  }>({});
  const [basinFields, setBasinFields] = useState<{
    [barCode: number]: Field[] | undefined;
  }>([]);
  const [basinReservoirs, setBasinReservoirs] = useState<{
    [barCode: number]: Reservoir[] | undefined;
  }>([]);
  const [checkedFieldIds, setCheckedFieldIds] = useState<Set<number>>(
    new Set()
  );
  const [checkedReservoirIds, setCheckedReservoirIds] = useState<Set<number>>(
    new Set()
  );

  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [loadingNodes, setLoadingNodes] = useState<string[]>([]);

  const [addingMode, setAddingMode] = useState(false);
  const [editingMode, setEditingMode] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [filter, setFilter] = useState("");

  const screenHeight = useScreenHeight();

  useEffect(() => {
    // Exit adding/editing mode when switching to a different project
    setEditingMode(false);
    setAddingMode(false);
    setFilter("");
  }, [props.projectCode]);

  useEffect(() => {
    setExpandedNodes([]);
    setCountryBasins({});
    setBasinFields([]);
    setBasinReservoirs([]);

    // We clear these when projectCode is changed because each project has different countries/basins/fields/reservoirs assigned to it
    // We clear these when editingMode changes because in edit mode we want to display more countries/basins/fields/reservoirs
    // We clear these when filter changes because filtering is done on the backend API side, so we need to reload
  }, [props.projectCode, editingMode, filter]);

  const [countriesProjectCode, setCountriesProjectCode] = useState<string>();
  const [countriesFilter, setCountriesFilter] = useState<string>();

  useEffect(() => {
    if (props.projectCode && props.projectName) {
      // Project selected. Load countries for that project.
      // Unless we're in adding mode, then load all countries.
      setCountriesProjectCode(addingMode ? "" : props.projectCode);
      setCountriesFilter(filter);
    } else {
      // No project selected. Don't load countries.
      setCountriesProjectCode(undefined);
      setCountriesFilter(undefined);
    }
  }, [props.projectCode, props.projectName, addingMode, filter]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCountries([]);

      if (typeof countriesProjectCode === "undefined" || isDialogOpen) return;

      setIsLoadingCountries(true);
      apiGetCountries({
        projectCode: countriesProjectCode,
        fieldOrReservoirCodeOrNameContains: countriesFilter,
        hasBasinsAreasRegions: true,
      })
        .then((countries) => {
          setIsLoadingCountries(false);
          setCountries(countries);
        })
        .catch((e) => {
          setIsLoadingCountries(false);
          setCountries([]);

          console.log("Error fetching countries: " + e.message);
        });
    }, 100);

    return () => clearTimeout(timeout);
  }, [countriesProjectCode, countriesFilter, isDialogOpen]);

  useEffect(() => {
    if (!props.projectCode) return;

    setIsLoadingProjectFields(true);
    apiGetProjectFields(props.projectCode)
      .then((data) => {
        setIsLoadingProjectFields(false);
        setCheckedFieldIds(new Set(data.map((x) => x.fieldId)));
      })
      .catch((e) => {
        setIsLoadingProjectFields(false);
        setCheckedFieldIds(new Set());

        console.log("Error fetching project fields: " + e.message);
      });

    setIsLoadingProjectReservoirs(true);
    apiGetProjectReservoirs(props.projectCode)
      .then((data) => {
        setIsLoadingProjectReservoirs(false);
        setCheckedReservoirIds(new Set(data.map((x) => x.reservoirId)));
      })
      .catch((e) => {
        setIsLoadingProjectReservoirs(false);
        setCheckedReservoirIds(new Set());

        console.log("Error fetching project reservoirs: " + e.message);
      });
  }, [props.projectCode]);

  const loadBasins = (countryCode: string, nodeId: string) => {
    if (loadingNodes.some((x) => x === nodeId)) return;

    setLoadingNodes((nodes) => [...nodes, nodeId]);
    apiGetBasinsAreasRegions({
      countryCode: countryCode,
      projectCode: !editingMode ? props.projectCode : undefined,
      fieldOrReservoirCodeOrNameContains: filter,
      hasFieldsOrReservoirs: !editingMode || filter ? true : undefined,
    })
      .then((basins) => {
        setLoadingNodes((nodes) => nodes.filter((x) => x !== nodeId));

        for (const basin of basins) {
          basin.coCode = countryCode;
        }

        setCountryBasins((x) => ({ ...x, [countryCode]: basins }));
      })
      .catch((e) => {
        setLoadingNodes((nodes) => nodes.filter((x) => x !== nodeId));

        console.log("Error fetching basins: " + e.message);
      });
  };

  const loadBasinFields = (barCode: number, coCode: string, nodeId: string) => {
    if (loadingNodes.some((x) => x === nodeId)) return;

    setLoadingNodes((nodes) => [...nodes, nodeId]);
    apiGetFields({
      codeOrNameContains: filter,
      barCode: barCode,
      countryCode: coCode,
      projectNo: editingMode ? undefined : props.projectCode,
    })
      .then((fields) => {
        setLoadingNodes((nodes) => nodes.filter((x) => x !== nodeId));

        setBasinFields((x) => ({ ...x, [barCode]: fields }));
      })
      .catch((e) => {
        setLoadingNodes((nodes) => nodes.filter((x) => x !== nodeId));

        console.log("Error fetching basin fields: " + e.message);
      });
  };

  const loadBasinReservoirs = (
    barCode: number,
    coCode: string,
    nodeId: string
  ) => {
    if (loadingNodes.some((x) => x === nodeId)) return;

    setLoadingNodes((nodes) => [...nodes, nodeId]);
    apiGetReservoirs({
      codeOrNameContains: filter,
      barCode: barCode,
      countryCode: coCode,
      projectNo: editingMode ? undefined : props.projectCode,
    })
      .then((reservoirs) => {
        setLoadingNodes((nodes) => nodes.filter((x) => x !== nodeId));

        setBasinReservoirs((x) => ({ ...x, [barCode]: reservoirs }));
      })
      .catch((e) => {
        setLoadingNodes((nodes) => nodes.filter((x) => x !== nodeId));

        console.log("Error fetching basin reservoirs: " + e.message);
      });
  };

  const loadNodes = (nodeIds: string[]) => {
    for (const nodeId of nodeIds) {
      if (nodeId.startsWith("CNT_")) {
        // Country
        // CNT_cocode
        let coCode = nodeId.substring(4);

        if (typeof countryBasins[coCode] === "undefined") {
          loadBasins(coCode, nodeId);
        }
      } else if (nodeId.startsWith("FLDBAS_")) {
        // Basin Fields
        // FLDBAS_cocode_barcode
        let coCode = nodeId.substring(7, 10);
        let barCode = Number(nodeId.substring(11));

        if (typeof basinFields[barCode] === "undefined") {
          loadBasinFields(barCode, coCode, nodeId);
        }
      } else if (nodeId.startsWith("RESBAS_")) {
        // Basin Reservoirs
        // RESBAS_cocode_barcode
        let coCode = nodeId.substring(7, 10);
        let barCode = Number(nodeId.substring(11));

        if (typeof basinReservoirs[barCode] === "undefined") {
          loadBasinReservoirs(barCode, coCode, nodeId);
        }
      }
    }
  };

  const fieldCheckedChanged = (fieldId: number, checked: boolean) => {
    setCheckedFieldIds((current) => {
      const next = new Set(current);
      if (checked) {
        next.add(fieldId);
      } else {
        next.delete(fieldId);
      }
      return next;
    });
  };

  const reservoirCheckedChanged = (reservoirId: number, checked: boolean) => {
    setCheckedReservoirIds((current) => {
      const next = new Set(current);
      if (checked) {
        next.add(reservoirId);
      } else {
        next.delete(reservoirId);
      }
      return next;
    });
  };

  const saveChanges = () => {
    setIsSaving(true);
    apiSaveProjectProperties(
      props.projectCode,
      Array.from(checkedFieldIds),
      Array.from(checkedReservoirIds)
    )
      .then(() => {
        setIsSaving(false);

        setIsDialogOpen(true);

        props.onProjectPropertiesUpdate(
          props.projectCode,
          checkedFieldIds.size,
          checkedReservoirIds.size
        );
      })
      .catch((e) => {
        setIsSaving(false);

        console.log("Error saving selected project properties: " + e.message);
      });
  };

  return (
    <>
      <AlertDialog
        isOpen={isDialogOpen}
        title={"Project Properties"}
        text={"Changes saved successfully."}
        closeHandler={() => setIsDialogOpen(false)}
      />

      <ProjectEditToolbar
        projectCode={props.projectCode}
        projectName={props.projectName}
        addingMode={addingMode}
        editingMode={editingMode}
        editingEnabled={props.editingEnabled}
        filterPropertiesText={filter}
        saveChangesHandler={saveChanges}
        updateFormHandler={() =>
          props.onProjectPropertiesUpdate(props.projectCode, 1, 1)
        }
        editProjectHandler={() => {
          setEditingMode(true);
          setAddingMode(false);
        }}
        addProjectPropertiesHandler={() => {
          setEditingMode(true);
          setAddingMode(true);
        }}
        cancelEditProjectHandler={() => {
          setEditingMode(false);
          setAddingMode(false);
          setFilter("");
        }}
        filterPropertiesHandler={(v) => setFilter(v)}
      />

      <Box marginTop={1} marginBottom={2}>
        <Divider />
      </Box>

      {!props.projectCode && (
        <Typography variant="caption" color="textSecondary">
          No project selected.
        </Typography>
      )}

      {props.projectCode && !props.projectName && (
        <Typography variant="caption" color="textSecondary">
          Project {props.projectCode} does not exist.
        </Typography>
      )}

      {isLoadingCountries && <CircularProgress />}

      <Box maxHeight={screenHeight - 380} style={{ overflow: "auto" }}>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expandedNodes}
          onNodeToggle={(_, nodeIds) => {
            setExpandedNodes(nodeIds);
            loadNodes(nodeIds);
          }}
          style={{
            opacity:
              isLoadingProjectFields || isLoadingProjectReservoirs || isSaving
                ? 0.5
                : 1.0,
          }}
        >
          {countries.map((country) => (
            <CountryNode
              key={country.code}
              country={country}
              countryBasins={countryBasins}
              basinFields={basinFields}
              basinReservoirs={basinReservoirs}
              checkedFieldIds={checkedFieldIds}
              checkedReservoirIds={checkedReservoirIds}
              onFieldCheckedChanged={fieldCheckedChanged}
              onReservoirCheckedChanged={reservoirCheckedChanged}
              loadingNodes={loadingNodes}
              editingMode={editingMode}
            />
          ))}
        </TreeView>
      </Box>
    </>
  );
}
