import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ReactElement, useState } from "react";
import { apiUploadFile, UploadFileResponse } from "../api";

interface ProjectPropertiesImportDialogProps {
  projectCode: string;
  projectName: string;
  open: boolean;
  onClose: (uploadType: number, result?: UploadFileResponse) => void;
}

export default function ProjectPropertiesImportDialog(
  props: ProjectPropertiesImportDialogProps
): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [uploadExcelType, setUploadExcelType] = useState(1);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const uploadFile = () => {
    if (fileToUpload === null) {
      setErrorMessage("No file selected.");
      return;
    }

    setIsLoading(true);
    apiUploadFile(props.projectCode, uploadExcelType, fileToUpload)
      .then((data) => {
        setIsLoading(false);
        setErrorMessage("");

        if (data.isValid === false) {
          setErrorMessage(data.errorMessage);
          return;
        }

        props.onClose(uploadExcelType, data);
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage("Error while trying to upload file.");
      });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={props.open}>
      <DialogTitle id="alert-dialog-title">
        Import Properties for Project {props.projectCode} {props.projectName}
      </DialogTitle>
      <DialogContent dividers>
        <Backdrop open={isLoading} style={{ zIndex: 100 }}>
          <CircularProgress />
        </Backdrop>

        <FormControl fullWidth>
          <InputLabel id="upload-excel-type">Property Type</InputLabel>
          <Select
            labelId="upload-excel-type"
            fullWidth
            value={uploadExcelType}
            onChange={(e) => setUploadExcelType(e.target.value as number)}
          >
            <MenuItem value={1}>Fields</MenuItem>
            <MenuItem value={2}>Reservoirs</MenuItem>
          </Select>
        </FormControl>

        <div
          style={{
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          <input
            type="file"
            accept=".xlsx,.xltx"
            onChange={(event) =>
              setFileToUpload(event.target.files?.item(0) || null)
            }
          />
        </div>

        {errorMessage && (
          <Alert variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}

        <Divider />

        <Grid
          container
          style={{
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <Grid item xs={6}>
            <Link
              href={process.env.PUBLIC_URL + "/fieldstemplate.xlsx"}
              target="_blank"
            >
              Download fields template
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Link
              href={process.env.PUBLIC_URL + "/reservoirstemplate.xlsx"}
              target="_blank"
            >
              Download reservoirs template
            </Link>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => props.onClose(0)}>
          Cancel
        </Button>
        <span style={{ padding: "0.2rem" }}>&nbsp;</span>
        <Button color="primary" autoFocus onClick={uploadFile}>
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
}
