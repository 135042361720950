import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
} from "@material-ui/core";
import { ReactElement, useEffect, useState } from "react";
import { apiGetProjectInfos } from "../api";
import { useSearchParam } from "../hooks";
import { ProjectInfo } from "../types";
import CountryBasins from "./CountryBasins";
import ProjectReports from "./ProjectReports";
import ProjectSelect from "./ProjectSelect";

interface MainFormProps {
  isAdmin: boolean;
}

export default function MainForm(props: MainFormProps): ReactElement {
  const [projectCode, setProjectCode] = useSearchParam("project", "");

  const [isLoading, setIsLoading] = useState(false);

  const [projects, setProjects] = useState<ProjectInfo[]>([]);

  const [projectName, setProjectName] = useState("");

  const [editingEnabled, setEditingEnabled] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    apiGetProjectInfos()
      .then((data) => {
        setIsLoading(false);
        setProjects(data.projects);
      })
      .catch((e) => {
        setIsLoading(false);
        setProjects([]);

        console.log("Error fetching project infos: " + e.message);
      });
  }, []);

  useEffect(() => {
    const project = projects.find((p) => p.id === projectCode);
    if (project) {
      setProjectName(project.name);
      setEditingEnabled(
        props.isAdmin || !project.historical || !project.hasProperties
      );
    } else {
      setProjectName("");
      setEditingEnabled(false);
    }
  }, [props.isAdmin, projectCode, projects]);

  const projectPropertiesChanged = (
    projectCode: string,
    fieldCount: number,
    reservoirCount: number
  ) => {
    // Update the project info in-memory without refetching data from server
    setProjects((current) =>
      current.map((p) =>
        p.id === projectCode
          ? {
              ...p,
              fieldCount,
              reservoirCount,
              hasProperties: fieldCount > 0 || reservoirCount > 0,
            }
          : p
      )
    );
  };

  return (
    <Container component="main" maxWidth="xl" style={{ marginTop: "2rem" }}>
      <Backdrop open={isLoading} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Box component={Paper}>
            <ProjectSelect
              projects={projects}
              projectCode={projectCode}
              onChange={(x) => setProjectCode(x)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Box
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <Box component={Paper} padding={2} style={{ flexGrow: 1 }}>
              <CountryBasins
                projectCode={projectCode}
                projectName={projectName}
                editingEnabled={editingEnabled}
                onProjectPropertiesUpdate={projectPropertiesChanged}
              />
            </Box>

            <Box component={Paper} padding={2} marginTop={1}>
              <ProjectReports
                projectCode={projectCode}
                projectName={projectName}
                editingEnabled={editingEnabled}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
